import { Box, Modal, Typography } from "@mui/material";
import Clock from "../../Assets/icons/clock.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/Close.svg";
import moment from "moment";
import React from "react";

const AnnouncementModalView = ({ item, handleModalClose }) => {
  const modalClose = () => {
    handleModalClose();
  };
  console.log("announcement modal ",item)
  const getLocalTime = (time) => {
    // let localTime = get12HrsTime(moment.utc(time).local().format('HH:mm '))
    let localTime = moment.utc(time).local().format("DD MMM YYYY hh:mm A");
    return localTime;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: `${window.innerWidth * 0.6}px`,
    bgcolor: "background.paper",
    border: "1px solid #808080",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
    outline: "none",
    overflowY: "auto",
  };

  const stripHtmlTags = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const get12HrsTime = (time) => {
    if (time !== null && time !== undefined) {
      return moment(time, "HH:mm:ss").format("h:mm A");
    } else {
      return "None";
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => modalClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ color: "#123FA9" }}>
          <Box
            sx={{
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "1.275rem", fontWeight: 600 }}>
              Announcements
            </Typography>
            <CloseIcon
              style={{ height: "25px", width: "25px", cursor: "pointer" }}
              onClick={() => modalClose()}
            />
          </Box>
          <Box
            style={{
              maxHeight: `${window.innerHeight * 0.7}px`,
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{ display: "flex", width: "100%", position: "relative" }}
            >
              <Box
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  background:
                    "linear-gradient(to bottom,rgb(90, 90, 90, 0.1) 0%, rgb(90, 90, 90, 0.15) 20%,rgb(90, 90, 90, 0.18) 20%, rgb(90, 90, 90, 0.2) 40%,rgb(90, 90, 90, 0.3) 60%, rgb(90, 90, 90, 0.4) 80%, rgb(90, 90, 90, 0.5) 100%)",
                  color: "white",
                  fontSize: "1.15rem",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  width: "-webkit-fill-available",
                  gap: "5px",
                }}
              >
                <Typography variant="p" sx={{ width: "auto" }}>
                  {item.title}
                </Typography>
                {item.announcement_type_name !== null && (
                  <Typography
                    variant="p"
                    sx={{ fontSize: "1rem", color: `${item.color_code}` }}
                  >
                    {item.announcement_type_name}
                  </Typography>
                )}
              </Box>
              {item.attachments.length > 0 && (
                <img
                  src={item.attachments[0].filelocation}
                  alt={item.attachments[0].filename}
                  style={{
                    height: "300px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              )}

              {item.attachments.length === 0 && (
                <Box
                  sx={{
                    height: `${window.innerHeight * 0.3}px`,
                    background: "white",
                  }}
                ></Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 0",
                gap: "10px",
              }}
            >
              <Typography varaint="h4" sx={{ fontSize: "1rem" }}>
                <pre
                  style={{
                    overflowX: "auto",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontSize: "15px",
                  }}
                >
                  {" "}
                  {stripHtmlTags(item.short_desc)}
                </pre>
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {item.attachments.length > 1 &&
                  item.attachments
                    .slice(1, Number(item.attachments.length))
                    .map((ele, index) => (
                      <img
                        src={ele.filelocation}
                        alt={ele.filename}
                        key={index}
                        style={{
                          height: "125px",
                          width: "125px",
                          objectFit: "contain",
                          borderRadius: "10px",
                        }}
                      />
                    ))}
              </Box>
            </Box>
            <Box>
          <Typography
            sx={{
              my: 1,
              // mt:2,
              display: item?.others?.length > 0 ? "block" : "none",
            }}
          >
            EXTERNAL LINKS
          </Typography>
            <ol style={{color:"#0F2C6F"}}>
                {item?.others?.map ((item,index) => (
                  <a href={item?.filelocation} target="_blank" style={{textDecoration:"none", cursor:"pointer",color:"#2d2dcd", lineHeight:"30px", fontWeight:600}}><li key={index}>{item?.filename }</li></a>
                ))}
            </ol>
        </Box>
            <Box
              sx={{
                background: "#F8F9FB",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignSelf: "flex-start",
                    width: "50%",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <img src={Clock} alt={"timeIcon"} width="20" />
                    <Typography variant="p">Hours</Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "center",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      {item.trigger === 1
                        ? moment(item.published_on).format("DD MMM yyyy HH:mm")
                        : moment(item.schedule_start_time).format(
                            "DD MMM yyyy HH:mm"
                          )}
                    </Typography>
                    <Typography>-</Typography>
                    <Typography>
                      {moment(item.schedule_end_time).format(
                        "DD MMM YYYY HH:mm"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AnnouncementModalView;
