import React from "react";
import Chart from "react-apexcharts";

const ServicePercentWiseGraph = () => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "Cargo Lift",
      data: [44, -1, 41, 67, 22, 43],
    },
    {
      name: "Light Bulb",
      data: [13, 23, 20, 8, 13, -4],
    },
    {
      name: "Aircon",
      data: [11, 17, -5, 15, 21, 14],
    },
    {
      name: "Security Clearance",
      data: [21, -23, 25, 13, 22, 8],
    },
    {
      name: "Miscellaous Forms",
      data: [21, -23, 25, 13, 22, 8],
    },
  ];

  return (
    <div style={{ marginTop: "10px" }}>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ServicePercentWiseGraph;
