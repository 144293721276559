import {
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  Chip,
  Menu,
  IconButton,
  Tooltip,
  Stack,
  Switch,
  useMediaQuery 
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes, { element } from "prop-types";
import styled from "@emotion/styled";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { ReactComponent as UploadIcon } from "../../Assets/icons/Upload.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxTerms.svg";
import Location from "../../Assets/icons/location.svg";
import { ReactComponent as Clock } from "../../Assets/icons/clock.svg";
import Phone from "../../Assets/icons/phone.png";
import Email from "../../Assets/icons/email.png";
import PhoneInput from "react-phone-input-2";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import * as eventsAndPromotionAction from "../../redux/actions/EventsAndPromotionAction";
import { ReactComponent as ArrowIcon } from "../../Assets/icons/back.svg";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../common/Loader";
import ToastAlert from "../common/Alert";
import {
  getYYYYMMDDFormat,
  getDateTimeFormat,
  preventDatePickerKeyboard,
  MenuProps,
} from "../../Utils/utils";
import Alert from "../common/faultalert";
import moment from "moment";
import WifiIcon from "@mui/icons-material/Wifi";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
import { ReactComponent as DeleteIcon } from '../../Assets/uploadIcons/DeleteIcon.svg';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import moments from "moment-timezone";
import { getPlatformName, getDateFormat } from "./CreateEvent";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ReactComponent as CropClose } from "../../Assets/icons/CropClose.svg";
import { BorderLinearProgress } from "./CreateEvent";
import { getFileIcon } from "./CreateEvent";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import axios from "axios";
import { getDescriptionTerms } from "../Sustainability/CreateSustainable";
import { getPopupToggle } from "./CreateEvent";

const PrimaryTypography = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 550,
  fontSize: "16px",
});
const SecondaryTypography = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 500,
  fontSize: "14px",
});

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#6F80A9",
  textTransform: "none",
  fontSize: "12px",
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTypography = styled(Typography)({
  fontSize: "13px",
  color: " #0F2C6F",
  fontWeight: 550,
});

const checkedStyle = {
  backgroundColor: "#FFFFFF",
  color: "#6F80A9",
  border: "1px solid #6F80A9",
  ml: 2,
  "&:hover": { backgroundColor: "#FFFFFF" },
};
const uncheckedStyle = {
  backgroundColor: "#123FA9",
  color: "#FFFFFF",
  ml: 2,
  "&:hover": { backgroundColor: "#123FA9" },
};

const dailyData = [
  { id: 1, day: "Monday", FromTime: "", toTime: "", isDisable: false },
  { id: 2, day: "TuesDay", FromTime: "", toTime: "", isDisable: false },
  { id: 3, day: "Wednesday", FromTime: "", toTime: "", isDisable: false },
  { id: 4, day: "Thursday", FromTime: "", toTime: "", isDisable: false },
  { id: 5, day: "Friday", FromTime: "", toTime: "", isDisable: false },
  { id: 6, day: "Saturday", FromTime: "", toTime: "", isDisable: false },
  { id: 7, day: "Sunday", FromTime: "", toTime: "", isDisable: false },
];

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ indent: "-1" }, { indent: "+1" }],
];
const module = {
  toolbar: toolbarOptions,
  keyboard: {
    bindings: {
      "list autofill": {
        prefix: /^\s*()$/,
      },
    },
  },
};
const CreatePromotion = ({
  getPromotionProperties,
  createPromotion,
  promotionProperties,
  promotionCreateStatus,
  eventPromotionStateNull,
  getEandPDetails,
  eventAndPromotionDetails,
  updateEandPDraft,
  updateDraftStatus,
}) => {
  let { id, reopen, edit } = useParams();
  let navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [coverImage, setCoverImage] = useState([]);
  const [croppedCoverImage, setCroppedCoverImage] = useState([]);
  const [aditionalImages, setAditionalImages] = useState([]);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [postType, setPostType] = useState("scheduled");
  const [eventData, setEventData] = useState({
    eventStartDate: null,
    eventEndDate: null,
    promotionStartDate: null,
    promotionEndDate: null,
  });
  console.log(eventData)
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [dailyDatas, setDailyDatas] = useState([...dailyData]);
  const [error, setError] = useState({});
  const [savedPropertiesData, setSavedPropertiesData] = useState([]);
  const [savedPropertiesId, setSavedPropertiesId] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [toastData, setToastData] = useState({
    message: "",
    status: false,
    color: "",
  });
  const [startHours, setStartHours] = useState();
  const [endHours, setEndHours] = useState();
  const [alertStatus, setAlertStatus] = useState(false);
  const [isCoverImageRemoved, setIsCoverImageRemoved] = useState(null);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [termsAndConditionLength, setTermsAndConditionLength] = useState(0);
  const [scheduleStartHours, setScheduleStartHours] = useState();
  const [scheduleEndHours, setScheduleEndHours] = useState();
  const [uploadedImg, setUploadedImg] = useState(null);
  const [submitBool, setSubmitBool] = useState(false);
  const [croppedImg, setCroppedImg] = useState(null);
  const [cropperKey, setCropperKey] = useState(Date.now());
  const [imagename, setCoverImageName] = useState("");
  const [filterDailyData, setFilterDailyData] = useState([]);
  const [isFilterDailyData, setIsFilterDailyData] = useState(false);
  const [isDailyDataEmpty, setIsDailyDataEmpty] = useState(false);
  const [isCroppedSubmit, setIsCroppedSubmit] = useState(false);
  const currentTab = localStorage.getItem("currentScreen");
  const [tooltipId, setTooltipId] = useState(null);
  const [apiRequestOngoing, setApiRequestOngoing] = useState(false)
  const [uploadErrorMsg, setUploadErrorMsg] = useState("")
  const [isToggle, setIsToggle] = useState(false); //for Does this appear to the user as a popup?
  const isWrap = useMediaQuery('(max-width:1909px)');

  const cropperRef = useRef(null);
  const onCrop = (e) => {
    if (isCroppedSubmit) return;
    const cropper = cropperRef.current?.cropper;
    const ur = cropper.getCroppedCanvas().toDataURL();
    const fd = new FormData();
    fetch(ur)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], imagename, {
          type: coverImage[0].file.type,
        });
        setCroppedCoverImage([file]);

        // fd.append('image', file)
      });
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  };

  let today = new Date();

  useEffect(() => {
    getPromotionProperties();
    getStartHours(0);
    getScheduleStartHours(0);
    getEndHours(0);
    getScheduleEndHours(0);
    if (id != "null") {
      getEandPDetails({ id: parseInt(id) });
    }
  }, []);

  useEffect(() => {
    const promotionStart = getDateFormat(
      eventAndPromotionDetails?.schedule_from_datetime,
      id
    );
    const promotionEnd = getDateFormat(
      eventAndPromotionDetails?.schedule_to_datetime,
      id
    );
    setEventData((prev) => ({
      ...prev,
      promotionStartDate:
        promotionStart == null ? null : promotionStart?.dateObject,
      promotionEndDate: promotionEnd == null ? null : promotionEnd?.dateObject,
      // promotionStartTime: eventAndPromotionDetails?.schedule_from_datetime_format,
      // promotionEndTime:eventAndPromotionDetails?.schedule_to_datetime_format == "23:59" ? "23:00" : eventAndPromotionDetails?.schedule_to_datetime_format,
      promotionStartTime:
        id == "null"
          ? null
          : eventAndPromotionDetails?.schedule_from_datetime_format,
      promotionEndTime:
        id == "null"
          ? null
          : eventAndPromotionDetails?.schedule_to_datetime_format == "23:59"
          ? "23:00"
          : eventAndPromotionDetails?.schedule_to_datetime_format,
    }));
  }, [postType]);

  useEffect(() => {
    setSavedPropertiesData(promotionProperties[1]?.data);
  }, [promotionProperties]);

  // const getPlatformName = (id) => {
  //     if(id) {
  //     const platforms = [ {app: false, dashboard: false },{app: true, dashboard: false },{app: false, dashboard: true },{app: true, dashboard: true }]
  //     return platforms[id];
  //     }
  // }

  const getSameHours = (data) => {
    if (data) {
      const mondayStartTime = data[0]?.from_time || data[0]?.FromTime;
      const mondayToTime = data[0]?.to_time || data[0]?.toTime;
      const match = data.every(
        (item) =>
          (item.from_time === mondayStartTime ||
            item.FromTime === mondayStartTime) &&
          (item.to_time === mondayToTime || item.toTime === mondayToTime)
      );
      const closeStatus = data.every(
        (item) => item?.closed_status === 0 || item?.isDisable == false
      );
      const sameHoursCheckBox = closeStatus && match ? true : false;
      return sameHoursCheckBox;
    }
  };
  useEffect(() => {
    let arr = savedPropertiesData.filter((value) => {
      return value.id == savedPropertiesId;
    });
    setEventData((prev) => ({
      ...prev,
      name: arr[0]?.name,
      contact_email: arr[0]?.email,
      address: arr[0]?.address,
      mobile: `${arr[0]?.mobile_code || '65'}${arr[0]?.mobile_no || ""}`,
      mobileCode: arr[0]?.mobile_code?.slice(1) ,
      mobileNumber: arr[0]?.mobile_no,
    }));
  }, [savedPropertiesId]);

  useEffect(() => {
    let promotionDate = new Date(eventData.promotionStartDate);
    if (promotionDate > today) {
      getScheduleStartHours(0);
    } else {
      getScheduleStartHours(today.getHours() + 1);
    }
  }, [eventData.promotionStartDate, eventAndPromotionDetails]);

  useEffect(() => {
    let promotionDate = new Date(eventData.promotionEndDate);
    if (promotionDate > today) {
      getScheduleEndHours(0);
    }
  }, [eventData.promotionStartTime]);

  const getScheduleStartHours = (minTime) => {
    let timeIntrevels = [];
    for (let i = minTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    // const startHour = eventAndPromotionDetails?.schedule_from_datetime_format
    // timeIntrevels.push(startHour);
    const startHour = eventAndPromotionDetails?.schedule_from_datetime_format;
    // const startHour = getDateFormat(eventAndPromotionDetails?.schedule_from_datetime, id)
    timeIntrevels.push(startHour);
    setScheduleStartHours([...timeIntrevels]);
  };

  const getScheduleEndHours = (minTime) => {
    let timeIntrevels = [],
      startTime = minTime === 0 ? 0 : parseInt(minTime.slice(0, 2)) + 1;

    for (let i = startTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    const endHours = getDateFormat(
      eventAndPromotionDetails?.schedule_to_datetime,
      id
    );
    timeIntrevels.push(endHours?.timeString);
    setScheduleEndHours([...timeIntrevels]);
  };

  const handleToggleChange = () => {
    setIsToggle(prev => !prev);
    setEventData((prev) => ({...prev, ['is_popup_display']: !isToggle}))
  }

  const postSetting = () => {
    setPostType(eventAndPromotionDetails?.trigger == 1 ? "now" : "scheduled");
    // const promotionStart = getDateFormat(eventAndPromotionDetails?.schedule_from_datetime, id);
    // const promotionEnd = getDateFormat(eventAndPromotionDetails?.schedule_to_datetime, id);
    const promotionStart = getDateFormat(
      eventAndPromotionDetails?.schedule_from_datetime,
      id
    );
    const promotionEnd =
      eventAndPromotionDetails?.trigger == 1
        ? eventAndPromotionDetails?.schedule_to_datetime == null
          ? null
          : eventAndPromotionDetails?.schedule_to_datetime?.slice(0, 10)
        : getDateFormat(eventAndPromotionDetails?.schedule_to_datetime, id);
    const promotionStartTime = getDateFormat(
      eventAndPromotionDetails?.schedule_from_datetime,
      id
    );
    const promotionEndTime = getDateFormat(
      eventAndPromotionDetails?.schedule_to_datetime,
      id
    );
    let obj = {
      // promotionStartDate:promotionStart ? promotionStart : null,
      // promotionEndDate:promotionEnd ? promotionEnd : null,
      // promotionStartTime: eventAndPromotionDetails?.schedule_from_datetime_format,
      // promotionEndTime: eventAndPromotionDetails?.schedule_to_datetime_format == "23:59" ? "23:00" : eventAndPromotionDetails?.schedule_to_datetime_format,
      promotionStartDate: promotionStart?.dateObject
        ? promotionStart.dateObject
        : null,
      promotionEndDate:
        eventAndPromotionDetails?.trigger == 1
          ? promotionEnd
            ? promotionEnd
            : null
          : promotionEnd?.dateObject
          ? promotionEnd.dateObject
          : null,
      promotionStartTime: promotionStartTime?.timeString
        ? promotionStartTime?.timeString?.slice(0, 6).trim()
        : null,
      promotionEndTime: promotionEndTime?.timeString
        ? promotionEndTime?.timeString?.slice(0, 6).trim()
        : null,
    };
    return obj;
  };

  useEffect(() => {
    if (id != "null") {
      setEventData((prev) => ({
        ...prev,
        title:
          eventAndPromotionDetails.title == null
            ? ""
            : eventAndPromotionDetails.title,
        address:
          eventAndPromotionDetails.address == null || undefined
            ? ""
            : eventAndPromotionDetails.address,
        contact_email:
          eventAndPromotionDetails.email == null
            ? ""
            : eventAndPromotionDetails.email,
        description:
          eventAndPromotionDetails.desc == null
            ? ""
            : getDescriptionTerms(eventAndPromotionDetails.desc),
        eventStartDate:
          eventAndPromotionDetails.event_start_time == null
            ? null
            : new Date(eventAndPromotionDetails.event_start_time),
        eventEndDate:
          eventAndPromotionDetails.event_end_time == null
            ? null
            : new Date(eventAndPromotionDetails.event_end_time),
        mobileCode:
          eventAndPromotionDetails.mobile_code == null
            ? ""
            : eventAndPromotionDetails.mobile_code.slice(1),
        mobileNumber:
          eventAndPromotionDetails.mobile_no == null || "-"
            ? ""
            : eventAndPromotionDetails.mobile_no,
        mobile:
          eventAndPromotionDetails?.mobile_code != null &&
          eventAndPromotionDetails?.mobile_no != null
            ? `${eventAndPromotionDetails.mobile_code}${eventAndPromotionDetails.mobile_no}`
            : "",
        name:
          eventAndPromotionDetails.name == null
            ? ""
            : eventAndPromotionDetails.name,
        terms_conditions:
          eventAndPromotionDetails.t_and_c == null
            ? ""
            : getDescriptionTerms(eventAndPromotionDetails.t_and_c),
        property:
          eventAndPromotionDetails.property_id == null
            ? ""
            : eventAndPromotionDetails.property_id,
        featureEnabled: eventAndPromotionDetails.is_save == 1 ? true : false,
        ...getPlatformName(eventAndPromotionDetails?.platform),
        sameHoursApplied: getSameHours(eventAndPromotionDetails?.timings),
        is_popup_display: eventAndPromotionDetails?.is_popup_display == 1 ? true : false,
        ...postSetting(),
      }));

      if(eventAndPromotionDetails?.is_popup_display == 1) {
        setIsToggle(true)
      } else {
        setIsToggle(false)
      }
      // console.log('eventAndPromotionDetails ',eventAndPromotionDetails);

      // if(eventAndPromotionDetails?.trigger == 1) {
      //     setPostType('now');
      // } else {
      //     setPostType('scheduled');
      // }

      if (
        eventAndPromotionDetails?.assets?.cover?.length == 0 ||
        eventAndPromotionDetails?.assets?.cover == null
      ) {
        // console.log('within if eventAndPromotionDetails?.assets?.cover ',eventAndPromotionDetails?.assets?.cover)
      } else {
        // console.log('eventAndPromotionDetails?.assets?.cover ',eventAndPromotionDetails?.assets?.cover)
        setCoverImage([
          {
            ...eventAndPromotionDetails?.assets?.cover,
            src: eventAndPromotionDetails?.assets?.cover?.new_filename,
          },
        ]);
      }
      if (eventAndPromotionDetails?.assets?.add_on?.length > 0) {
        let data = eventAndPromotionDetails?.assets?.add_on;
        let testArr = eventAndPromotionDetails?.assets?.add_on?.map((obj) => ({
          ...obj,
        }));
        for (let i = 0; i < testArr.length; i++) {
          testArr[i].src = testArr[i].new_filename;
        }
        setAditionalImages([...testArr]);
      }
      if (eventAndPromotionDetails?.assets?.others?.length > 0) {
        let data = eventAndPromotionDetails?.assets?.others;
        let duplicatedDocumentArray = eventAndPromotionDetails?.assets?.others?.map(
          (obj) => ({ ...obj })
        );
        for (let i = 0; i < duplicatedDocumentArray.length; i++) {
          duplicatedDocumentArray[i].src = duplicatedDocumentArray[i].new_filename;
        }
        setAdditionalDocuments([...duplicatedDocumentArray]);
      } else {
        setAdditionalDocuments([])
      }
      let hours = eventAndPromotionDetails?.timings?.map((value, index) => {
        return {
          id: index + 1,
          day: value.day,
          FromTime: value.from_time,
          submitFromTime: value.from_time,
          toTime: value.to_time,
          submittoTime: value.to_time,
          isDisable: value.closed_status == 0 ? false : true,
        };
      });
      setDailyDatas(hours);
    }
  }, [eventAndPromotionDetails]);

  useEffect(() => {
    if (promotionCreateStatus?.response_status == "success") {
      setLoaderStatus(false);
      setAlertStatus(false);
      setToastData({
        message: promotionCreateStatus?.response_message,
        status: true,
        color: "green",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "" });
        eventPromotionStateNull();
        navigate(-1);
      }, 5000);
    } else if (promotionCreateStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setAlertStatus(false);
      setToastData({
        message: promotionCreateStatus?.response_message,
        status: true,
        color: "red",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "" });
        eventPromotionStateNull();
      }, 5000);
    }
  }, [promotionCreateStatus]);

  useEffect(() => {
    if (updateDraftStatus?.response_status == "success") {
      setLoaderStatus(false);
      setAlertStatus(false);
      setToastData({
        message: updateDraftStatus?.response_message,
        status: true,
        color: "green",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "" });
        eventPromotionStateNull();
        navigate(-1);
      }, 5000);
    } else if (updateDraftStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setAlertStatus(false);
      setToastData({
        message: updateDraftStatus?.response_message,
        status: false,
        color: "red",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "" });
        eventPromotionStateNull();
      }, 5000);
    }
  }, [updateDraftStatus]);

  useEffect(() => {
    let promotionDate = new Date(eventData.promotionEndDate);
    if (promotionDate > today) {
      getEndHours(0);
    }
  }, [eventData?.promotionStartTime]);

  useEffect(() => {
    let arr = [];
    if (eventData.sameHoursApplied) {
      let fromTime = dailyDatas[0].FromTime;
      let toTime = dailyDatas[0].toTime;
      dailyDatas.forEach((element) => {
        let obj = { ...element, FromTime: fromTime, toTime: toTime };
        arr.push(obj);
      });
      setDailyDatas([...arr]);
    } else {
      // dailyDatas.forEach((element)=>{
      //     let obj = {...element,FromTime:'',toTime:''}
      //      arr.push(obj)
      //  })

      setDailyDatas(dailyDatas);
    }
  }, [eventData.sameHoursApplied]);

  // console.log('changed eventData ',eventData);

  const getPlatformData = () => {
    if (eventData.app && eventData.dashboard) {
      return 3;
    } else if (eventData.app && !eventData.dashboard) {
      return 1;
    } else if (eventData.dashboard && !eventData.app) {
      return 2;
    } else {
      return 0;
    }
  };

  const getStartHours = (minTime) => {
    let timeIntrevels = [];
    for (let i = minTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    //    const startHour = eventAndPromotionDetails?.event_start_time_format
    //    timeIntrevels.push(startHour);
    setStartHours([...timeIntrevels]);
  };

  const getEndHours = (minTime) => {
    let timeIntrevels = [],
      startTime = minTime === 0 ? 0 : parseInt(minTime.slice(0, 2)) + 1;

    for (let i = startTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    setEndHours([...timeIntrevels]);
  };
  const getOpenhours = () => {
    let arr = dailyDatas.map((value) => {
      return {
        from: value.FromTime || "",
        to: value.toTime || "",
        closed: value.isDisable.toString(),
      };
    });
    return JSON.stringify(arr);
  };

  const handleContinueButton = () => {
    let err = validate();
    // if(err === true && tabValue === 1){
    //   if( tabValue === 1){
    //     setTabValue(0)
    // }

    let arr = [];
    let documentsNewArr = [];
    let documentsExisArr = [];
    let existAddonImageId = aditionalImages.map((value) => {
      if (value.asset_id != null) {
        arr.push(value.asset_id);
      }
      return value.asset_id;
    });
    additionalDocuments?.forEach((value) => {
      if (!value?.filesize && value?.asset_id != null) {
        documentsNewArr.push(value?.asset_id);
      }
    });
    additionalDocuments?.forEach((value) => {
      if (value?.filesize && value?.asset_id != null) {
        documentsExisArr.push(value?.asset_id);
      }
    });
    let newAddonImage = aditionalImages.slice(0, 5).filter((value) => {
      return value.asset_id == undefined;
    });

    let startDate = `${eventData.eventStartDate?.getFullYear()}-${
      eventData.eventStartDate?.getMonth() < 9
        ? `0${eventData.eventStartDate?.getMonth() + 1}`
        : eventData.eventStartDate?.getMonth() + 1
    }-${
      eventData.eventStartDate?.getDate() < 10
        ? `0${eventData.eventStartDate?.getDate()}`
        : eventData.eventStartDate?.getDate()
    } 00:00:00`;
    let endDate = `${eventData.eventEndDate?.getFullYear()}-${
      eventData.eventEndDate?.getMonth() < 9
        ? `0${eventData.eventEndDate?.getMonth() + 1}`
        : eventData.eventEndDate?.getMonth() + 1
    }-${
      eventData.eventEndDate?.getDate() < 10
        ? `0${eventData.eventEndDate?.getDate()}`
        : eventData.eventEndDate?.getDate()
    } 23:59:59`;

    let sTime = eventData.promotionStartTime?.split(":") || null;
    let eTime = eventData.promotionEndTime?.split(":") || null;
    // let laterStartDate = postType=="scheduled" ?  `${eventData.promotionStartDate?.getFullYear()}-${eventData.promotionStartDate?.getMonth()<9 ? `0${eventData.promotionStartDate?.getMonth()+1}` : eventData.promotionStartDate?.getMonth()+1}-${eventData.promotionStartDate?.getDate()<10 ? `0${eventData.promotionStartDate?.getDate()}` : eventData.promotionStartDate?.getDate()} ${sTime[0]}:${sTime[1]}:00` : ""
    // let laterEndtDate = postType=="scheduled" ? `${eventData.promotionEndDate?.getFullYear()}-${eventData.promotionEndDate?.getMonth()<9 ? `0${eventData.promotionEndDate?.getMonth()+1}`: eventData.promotionEndDate?.getMonth()+1 }-${eventData.promotionEndDate?.getDate()<10 ? `0${eventData.promotionEndDate?.getDate()}` : eventData.promotionEndDate?.getDate()} ${eTime[0]}:${eTime[1]}:00` : ""

    // let postnowEndDate = `${eventData.promotionEndDate?.getFullYear()}-${eventData.promotionEndDate?.getMonth()<9 ? `0${eventData.promotionEndDate?.getMonth()+1}`: eventData.promotionEndDate?.getMonth()+1 }-${eventData.promotionEndDate?.getDate() < 10 ? `0${eventData.promotionEndDate?.getDate()}` : eventData.promotionEndDate?.getDate()} 23:59:59`
    // let mobile = eventData.mobile.slice(3).replace(/-/g,"")
    if (err == false) setTabValue(0);
    if (uploadedImg != null && !isCroppedSubmit) {
      setToastData({
        message: "Please submit cropped image",
        status: true,
        color: "red",
        icon: "close",
      });
      setTabValue(0);
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "", icon: "" });
      }, 5000);
      return;
    }
    if (err) {
      const convertUtc = (localDateTimeString) => {
        if (
          localDateTimeString.includes("undefined") ||
          localDateTimeString.includes("null")
        ) {
          return "";
        }
        const localDateTimes = moment(
          localDateTimeString,
          "YYYY-MM-DD HH:mm:ss"
        );

        const utcDateTimes = localDateTimes.utc().format("YYYY-MM-DD HH:mm:ss");

        return utcDateTimes;
      };

      const getHHMMSStime = () => {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        return formattedTime;
      };
      let data = {
        property_id: eventData.property,
        name: eventData.name,
        email: eventData?.contact_email ? eventData.contact_email : "",
        mobile_code:
          eventData?.mobileCode && eventData?.mobileNumber != ""
            ? `+${eventData?.mobileCode}`
            : "",
        mobile_no: eventData?.mobileNumber ? eventData.mobileNumber : "",
        address: eventData?.address == undefined ? "" : eventData.address,
        is_save: eventData.featureEnabled ? 1 : 0,
        platform: getPlatformData(),
        is_popup_display: getPopupToggle(eventData?.is_popup_display),
        title: eventData.title,
        desc: getDescriptionTerms(eventData?.description),
        tc_desc:
          eventData.terms_conditions == null ||
          eventData.terms_conditions == undefined ||
          eventData.terms_conditions == "undefined"
            ? ""
            : getDescriptionTerms(eventData?.terms_conditions),
        status: edit == "true" ? "Edit" : "Create",
        cover_img:
          id != "null" && isCoverImageRemoved == null ? "" : coverImage[0].file,
        cover_img_id:
          id != "null" && isCoverImageRemoved == null
            ? coverImage[0]?.asset_id
            : "",
        event_start_time: startDate
          ? startDate?.includes("undefined") || startDate?.includes("NaN")
            ? ""
            : startDate
          : "",
        event_end_time: endDate
          ? endDate?.includes("NaN") || endDate?.includes("NaN")
            ? ""
            : endDate
          : "",
        // start_date_time:  postType!='now' ?  convertUtc(`${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${eventData?.promotionStartTime}:00`) : convertUtc(`${getYYYYMMDDFormat(new Date())} ${getHHMMSStime(new Date())}`),
        // end_date_time:  postType!='now' ?  convertUtc(`${getYYYYMMDDFormat(eventData?.promotionEndDate)} ${eventData?.promotionEndTime}:00`): `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:59:59` ,
        start_date_time:
          postType != "now"
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${
                  eventData?.promotionStartTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(new Date())} ${getHHMMSStime(new Date())}`
              ).format("YYYY-MM-DD HH:mm:ss"),
        end_date_time:
          postType != "now"
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} ${
                  eventData?.promotionEndTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:30:00`
              ).format("YYYY-MM-DD HH:mm:ss"),
        triggerstatus: postType === "now" ? 1 : 2,
        open_hours: getOpenhours(),
        cropped_cover_img:
          id != "null" && croppedCoverImage[0] == undefined
            ? ""
            : croppedCoverImage[0] != undefined
            ? croppedCoverImage[0]
            : "",
        add_on_img_ids: arr,
        others_attachs: documentsNewArr,
      };

      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      for (let i = 0; i < newAddonImage.length; i++) {
        formData.append(`add_on_img[${i}]`, newAddonImage[i].file);
      }
      if (id != "null" && reopen === "false") {
        formData.append("id", id);
        formData.append("env_type", "Promotion");
        formData.append("exist_add_on_img", JSON.stringify(arr));
        formData.append("exists_others_attachs", documentsExisArr);
      }

      if (id != "null" && reopen === "false") {
        updateEandPDraft(formData);
      } else {
        createPromotion(formData);
      }
      setAlertStatus(false);
      setLoaderStatus(true);
      setTimeout(() => {
        setLoaderStatus(false);
      }, 25000);
    }
  };

  const saveAsDraft = () => {
    if (uploadedImg != null && !isCroppedSubmit) {
      setToastData({
        message: "Please submit cropped image",
        status: true,
        color: "red",
        icon: "close",
      });
      setTabValue(0);
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "", icon: "" });
      }, 5000);
      return;
    }
    let arr = [];
    let documentsNewArr = [];
    let documentsExisArr = [];
    let existAddonImageId = aditionalImages.map((value) => {
      if (value.asset_id != null) {
        arr.push(value.asset_id);
      }
      return value.asset_id;
    });
    additionalDocuments?.forEach((value) => {
      if (!value?.filesize && value?.asset_id != null) {
        documentsNewArr.push(value?.asset_id);
      }
    });
    additionalDocuments?.forEach((value) => {
      if (value?.filesize && value?.asset_id != null) {
        documentsExisArr.push(value?.asset_id);
      }
    });
    let newAddonImage = aditionalImages.slice(0, 5).filter((value) => {
      return value.asset_id == undefined;
    });

    let startDate =
      eventData.eventStartDate != null &&
      `${eventData.eventStartDate.getFullYear()}-${
        eventData.eventStartDate.getMonth() < 9
          ? `0${eventData.eventStartDate.getMonth() + 1}`
          : eventData.eventStartDate.getMonth() + 1
      }-${
        eventData.eventStartDate.getDate() < 10
          ? `0${eventData.eventStartDate.getDate()}`
          : eventData.eventStartDate.getDate()
      } 00:00:00`;
    let endDate =
      eventData.eventEndDate != null &&
      `${eventData.eventEndDate.getFullYear()}-${
        eventData.eventEndDate.getMonth() < 9
          ? `0${eventData.eventEndDate.getMonth() + 1}`
          : eventData.eventEndDate.getMonth() + 1
      }-${
        eventData.eventEndDate.getDate() < 10
          ? `0${eventData.eventEndDate.getDate()}`
          : eventData.eventEndDate.getDate()
      } 23:59:59`;

    let sTime = eventData.promotionStartTime?.split(":") || null;
    let eTime = eventData.promotionEndTime?.split(":") || null;
    // let laterStartDate =  postType=="scheduled" ?  `${eventData.promotionStartDate?.getFullYear()}-${eventData.promotionStartDate?.getMonth()<9 ? `0${eventData.promotionStartDate?.getMonth()+1}` : eventData.promotionStartDate?.getMonth()+1}-${eventData.promotionStartDate?.getDate()<10 ? `0${eventData.promotionStartDate?.getDate()}` : eventData.promotionStartDate?.getDate()} ${sTime[0]}:${sTime[1]}:00` : ""
    // let laterEndtDate = postType=="scheduled" ? `${eventData.promotionEndDate?.getFullYear()}-${eventData.promotionEndDate?.getMonth()<9 ? `0${eventData.promotionEndDate?.getMonth()+1}`: eventData.promotionEndDate?.getMonth()+1 }-${eventData.promotionEndDate?.getDate()<10 ? `0${eventData.promotionEndDate?.getDate()}` : eventData.promotionEndDate?.getDate()} ${eTime[0]}:${eTime[1]}:00` : ""

    // let postnowEndDate = `${eventData.promotionEndDate?.getFullYear()}-${eventData.promotionEndDate?.getMonth()<9 ? `0${eventData.promotionEndDate?.getMonth()+1}`: eventData.promotionEndDate?.getMonth()+1 }-${eventData.promotionEndDate?.getDate() < 10 ? `0${eventData.promotionEndDate?.getDate()}` : eventData.promotionEndDate?.getDate()} 23:59:59`

    const convertUtc = (localDateTimeString) => {
      if (
        localDateTimeString.includes("undefined") ||
        localDateTimeString.includes("null")
      ) {
        return "";
      }
      const localDateTimes = moment(localDateTimeString, "YYYY-MM-DD HH:mm:ss");

      const utcDateTimes = localDateTimes.utc().format("YYYY-MM-DD HH:mm:ss");

      return utcDateTimes;
    };
    const getHHMMSStime = () => {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      return formattedTime;
    };
    let data = {
      property_id: eventData.property == undefined ? "" : eventData.property,
      name: eventData.name == undefined ? "" : eventData.name,
      email:
        eventData?.contact_email == undefined ? "" : eventData?.contact_email,
      mobile_code:
        eventData?.mobileCode == undefined || eventData?.mobileCode == ""
          ? ""
          : `+${eventData.mobileCode}`,
      mobile_no:
        eventData?.mobileNumber == undefined || eventData?.mobileNumber == ""
          ? ""
          : eventData.mobileNumber,
      address: eventData.address == undefined ? "" : eventData.address,
      is_save: eventData.featureEnabled ? 1 : 0,
      platform: getPlatformData(),
      is_popup_display: getPopupToggle(eventData?.is_popup_display),
      title: eventData.title == undefined ? "" : eventData.title,
      desc: eventData.description == undefined ? "" : getDescriptionTerms(eventData?.description),
      tc_desc:
        eventData.terms_conditions == undefined
          ? ""
          : getDescriptionTerms(eventData?.terms_conditions),
      status: "Draft",
      // cover_img:(coverImage[0]?.file==undefined || (id!='null' &&isCoverImageRemoved==null)) ? '' : coverImage[0].file,
      // cover_img_id: (coverImage[0]?.file==undefined || (id!='null' &&isCoverImageRemoved==null)) ? coverImage[0]?.asset_id : '',
      cover_img:
        coverImage[0]?.file == "" || coverImage[0]?.file == null
          ? ""
          : coverImage[0]?.file,
      cover_img_id:
        coverImage[0]?.file == undefined || id != "null"
          ? coverImage[0]?.asset_id
          : "",
      // event_start_time:eventData.eventStartDate==null ? '' : startDate,
      // event_end_time:eventData.eventEndDate==null ? '' : endDate,
      event_end_time:
        eventData?.eventEndDate == null || eventData?.eventEndDate == ""
          ? ""
          : eventData?.eventEndTime == null || eventData?.eventEndTime == ""
          ? `${getYYYYMMDDFormat(eventData?.eventEndDate)} 23:30:00`
          : `${getYYYYMMDDFormat(eventData?.eventEndDate)} ${
              eventData?.eventEndTime
            }:00`,
      event_start_time:
        eventData?.eventStartDate == null || eventData?.eventStartDate == ""
          ? ""
          : eventData?.eventStartTime == undefined ||
            eventData?.eventStartTime == ""
          ? `${getYYYYMMDDFormat(eventData?.eventStartDate)} 00:00:00`
          : `${getYYYYMMDDFormat(eventData?.eventStartDate)} ${
              eventData?.eventStartTime
            }:00`,
      // start_date_time:  postType!='now' ?  convertUtc(`${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${eventData?.promotionStartTime}`) : convertUtc(`${getYYYYMMDDFormat(new Date())} ${getHHMMSStime(new Date())}`),
      // end_date_time: postType!='now' ?  convertUtc(`${getYYYYMMDDFormat(eventData?.promotionEndDate)} ${eventData?.promotionEndTime}:00`): eventData?.promotionEndDate !=null ? `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:59:59`:"" ,
      start_date_time:
        postType != "now"
          ? eventData?.promotionStartDate == null ||
            eventData?.promotionStartDate == ""
            ? ""
            : eventData?.promotionStartTime == null ||
              eventData?.promotionStartTime == ""
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionStartDate)} 00:00:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${
                  eventData?.promotionStartTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
          : moment(
              `${getYYYYMMDDFormat(new Date())} ${getHHMMSStime(new Date())}`
            ).format("YYYY-MM-DD HH:mm:ss"),
      end_date_time:
        postType != "now"
          ? eventData?.promotionEndDate == null ||
            eventData?.promotionEndDate == ""
            ? ""
            : eventData?.promotionEndTime == null ||
              eventData?.promotionEndTime == ""
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:30:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} ${
                  eventData?.promotionEndTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
          : eventData?.promotionEndDate == "" ||
            eventData?.promotionEndDate == null
          ? ""
          : moment(
              `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:30:00`
            ).format("YYYY-MM-DD HH:mm:ss"),
      triggerstatus: postType === "now" ? 1 : 2,
      open_hours: getOpenhours(),
      cropped_cover_img:
        id != "null" && croppedCoverImage[0] == undefined
          ? ""
          : croppedCoverImage[0] != undefined
          ? croppedCoverImage[0]
          : "",
      add_on_img_ids: arr,
      others_attachs: documentsNewArr,
    };

    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    for (let i = 0; i < newAddonImage.length; i++) {
      formData.append(`add_on_img[${i}]`, newAddonImage[i].file);
    }
    if (id != "null") {
      formData.append("id", id);
      formData.append("env_type", "Promotion");
      formData.append("exist_add_on_img", JSON.stringify(arr));
      formData.append("exists_others_attachs", documentsExisArr);
    }

    //API
    if (id != "null") {
      updateEandPDraft(formData);
    } else {
      createPromotion(formData);
    }
    setAlertStatus(false);
    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 25000);
  };

  const validate = () => {
    let newErrors = [],
      errorMessges = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (eventData.title === undefined || eventData.title == "") {
      newErrors.push(false);
      errorMessges["title"] = "Title field Required";
    } else {
      newErrors.push(true);
      errorMessges["title"] = "";
    }

    if (
      eventData.description === undefined ||
      eventData.description == "" ||
      descriptionLength == 0
    ) {
      newErrors.push(false);
      errorMessges["description"] = "Please fill out this field";
    } else {
      newErrors.push(true);
      errorMessges["description"] = "";
    }

    // if (eventData.contact_email === undefined || eventData.contact_email=='') {
    //     newErrors.push(false)
    //     errorMessges['contact_email'] = 'Email Required!'
    // } else if (!eventData.contact_email.match(emailRegex)) {
    //     newErrors.push(false)
    //     errorMessges['contact_email'] = 'Invalid Email!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['contact_email'] = ''
    // }

    if (
      eventData.name === undefined ||
      eventData.name == "undefinedundefined" ||
      eventData.name == ""
    ) {
      newErrors.push(false);
      errorMessges["name"] = "Enter Name";
    } else {
      newErrors.push(true);
      errorMessges["name"] = "";
    }

    // if (eventData.mobile === undefined || eventData.mobile=='undefinedundefined' || eventData.mobile=='') {
    //     newErrors.push(false)
    //     errorMessges['number'] = 'Contact Number Required'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['number'] = ''
    // }

    if (id == "null") {
      if (
        eventData.property == 2 &&
        (savedPropertiesId == undefined || savedPropertiesId == "")
      ) {
        newErrors.push(false);
        errorMessges["tenant"] = "Select Property";
      }
    }

    // if (eventData.eventStartDate === undefined || eventData.eventStartDate==null) {
    //     newErrors.push(false)
    //     errorMessges['eventStartDate'] = 'Date Required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['eventStartDate'] = ''
    // }

    // if (eventData.eventEndDate === undefined || eventData.eventEndDate==null) {
    //     newErrors.push(false)
    //     errorMessges['eventEndDate'] = 'End Date Required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['eventEndDate'] = ''
    // }

    // if (eventData.terms_conditions === undefined || eventData.terms_conditions=='' || termsAndConditionLength == 0) {
    //     newErrors.push(false)
    //     errorMessges['terms_conditions'] = 'Terms and conditions required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['terms_conditions'] = ''
    // }

    if (
      postType == "scheduled" &&
      (eventData.promotionStartDate === undefined ||
        eventData.promotionStartDate == null)
    ) {
      newErrors.push(false);
      errorMessges["promotionStartDate"] = "Start Date required!";
    } else {
      newErrors.push(true);
      errorMessges["promotionStartDate"] = "";
    }

    if (
      eventData.promotionEndDate === undefined ||
      eventData.promotionEndDate == null
    ) {
      newErrors.push(false);
      errorMessges["promotionEndDate"] = "End Date required!";
    } else {
      newErrors.push(true);
      errorMessges["promotionEndDate"] = "";
    }

    if (
      postType === "scheduled" &&
      (eventData.promotionStartTime === undefined ||
        eventData.promotionStartTime == null)
    ) {
      newErrors.push(false);
      errorMessges["promotionStartTime"] = "Start Time required!";
    } else if (
      currentTab == 1 &&
      postType === "scheduled" &&
      eventData.promotionStartTime != undefined &&
      eventData.promotionStartTime != ""
    ) {
      if (
        moment(eventData.promotionStartDate).format("YYYY-MM-DD") ==
          moment(today).format("YYYY-MM-DD") &&
        eventData.promotionStartTime <
          moment(new Date()).add(15, "minutes").format("HH:mm")
      ) {
        newErrors.push(false);
        errorMessges["promotionStartTime"] = "Choose valid time!";
      }
    } else {
      newErrors.push(true);
      errorMessges["promotionStartTime"] = "";
    }

    if (
      postType === "scheduled" &&
      (eventData.promotionEndTime === undefined ||
        eventData.promotionEndTime == null)
    ) {
      newErrors.push(false);
      errorMessges["promotionEndTime"] = "End Time required!";
    } else {
      newErrors.push(true);
      errorMessges["promotionEndTime"] = "";
    }

    if (coverImage.length < 1) {
      newErrors.push(false);
      errorMessges["coverImage"] = "CoverImage is Required";
    } else {
      newErrors.push(true);
      errorMessges["coverImage"] = "";
    }

    if (aditionalImages.length > 5) {
      newErrors.push(false);
      errorMessges["additionalImages"] = "Max 5 images only allowed";
    } else {
      newErrors.push(true);
      errorMessges["additionalImages"] = "";
    }

    // if(!eventData.app && !eventData.dashboard){
    //     newErrors.push(false)
    //     errorMessges['platform'] = 'Please select atleast one if you want to proceed'
    // }else {
    //     newErrors.push(true)
    //     errorMessges['platform'] = ''
    // }

    dailyDatas.forEach((element) => {
      if (!element.isDisable) {
        // if(element.FromTime==='' || element.FromTime==null){
        //     newErrors.push(false)
        //     errorMessges[`eventStartTime${element.day}`] = 'Start Time Required'
        // }else{
        //     newErrors.push(true)
        //     errorMessges[`eventStartTime${element.day}`] = ''
        // }
        // if(element.toTime==='' || element.toTime==null){
        //     newErrors.push(false)
        //     errorMessges[`eventEndTime${element.day}`] = 'End Time Required'
        // }else{
        //     newErrors.push(true)
        //     errorMessges[`eventEndTime${element.day}`] = ''
        // }
        if (element?.FromTime?.slice(0, 2) > element?.toTime?.slice(0, 2)) {
          newErrors.push(false);
          errorMessges[`validate${element.day}`] =
            "End Time should be greater than Start Time";
        } else {
          newErrors.push(true);
          errorMessges[`validate${element.day}`] = "";
        }
      }
    });

    let errorStatus = newErrors.includes(false);
    setError({ ...errorMessges });
    return !errorStatus;
  };

  const handleBackButton = () => {
    let params = [
      "title",
      "description",
      "contact_email",
      "terms_conditions",
      "property",
    ];
    let arr = [];
    for (let i = 0; i < params.length; i++) {
      if (
        eventData[params[i]] != "" &&
        eventData[params[i]] != undefined &&
        eventData[params[i]] != null
      ) {
        arr.push(true);
      } else {
        arr.push(false);
      }
    }
    if (edit == "false" && arr.includes(true)) {
      setAlertStatus(true);
    } else {
      if (uploadedImg != null && !isCroppedSubmit) return;
      navigate(-1);
    }
    localStorage.removeItem("currentScreen");
  };

  const handleDate = (e, datafrom) => {
    setEventData((prev) => ({ ...prev, [datafrom]: e }));
    setError({
      ...error,
      [datafrom]: "",
    });
  };
  const handleTime = (e, datafrom) => {
    setEventData((prev) => ({
      ...prev,
      [datafrom]: e.target.value,
      [`display${datafrom}`]: e,
    }));
    setError({
      ...error,
      [datafrom]: "",
    });
  };

  const handleDailyTime = (e, datafrom, rowId) => {
    // let Time;
    // Time = `${e.getHours() <= 9 ? `0${e.getHours()}` : `${e.getHours()}`}:${e.getMinutes() <= 9 ? `0${e.getMinutes()}` : `${e.getMinutes()}`}`;
    let { value } = e.target;

    setEventData((prev) => ({
      ...prev,
      [datafrom]: value,
      [`display${datafrom}`]: value,
    }));
    let updateData = [];
    dailyDatas.forEach((element) => {
      if (element.id === rowId) {
        updateData.push({
          ...element,
          [datafrom]: value,
          [`submit${datafrom}`]: value,
        });
      } else {
        updateData.push({ ...element });
      }
    });

    setDailyDatas([...updateData]);

    const mondayStartTime = updateData[0].FromTime;
    const mondayToTime = updateData[0].toTime;
    const match = updateData.every(
      (item) =>
        item.FromTime === mondayStartTime && item.toTime === mondayToTime
    );
    if (match == true) {
      setEventData((prev) => ({
        ...prev,
        sameHoursApplied: true,
      }));
    } else {
      setEventData((prev) => ({
        ...prev,
        sameHoursApplied: false,
      }));
    }

    setError({
      ...error,
      [datafrom]: "",
    });
  };

  const handleUploadButton = (e) => {
    setError({
      ...error,
      coverImage: "",
    });
    setUploadedImg(null);
    setSubmitBool(false);
    setUploadedImg(URL.createObjectURL(e.target.files[0]));
    let target = e.target.files;
    setCoverImageName(target[0].name);
    let arr = [];
    arr.push({
      id: 1,
      fileName: target[0].name,
      size: target[0].size,
      file: target[0],
      src: URL.createObjectURL(target[0]),
    });
    setCoverImage([...arr]);
  };
  const handleUploadMultipleButton = (e) => {
    let target = e.target.files;
    let arr = aditionalImages;
    if (target.length > 5) {
      setError({
        ...error,
        additionalImages: "Please select maximum 5 images only",
      });
    } else {
      for (let i = 0; i < target.length; i++) {
        arr.push({
          fileName: target[i].name,
          size: target[i].size,
          file: target[i],
          src: URL.createObjectURL(target[i]),
        });
      }
      setAditionalImages([...arr]);
      setError({
        ...error,
        additionalImages: "",
      });
    }
  };

  const removeAditionalImages = (indexId) => {
    let arr = aditionalImages.filter((item, index) => {
      if (index !== indexId) {
        return item;
      }
    });

    setAditionalImages([...arr]);
  };

  const getUniqueId = () => {
    let randomUniqueId;
    let isAlreadyExists;
    do {
      randomUniqueId = Math.floor(Math.random() * 10000);
      isAlreadyExists = additionalDocuments?.some(item => item.id === randomUniqueId);
      console.log("Checking ID:", randomUniqueId, "Exists:", isAlreadyExists);
    } while (isAlreadyExists);
  
    return randomUniqueId;
  };

  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: (event) => {
      console.log("event ", event);
      const progress = Math.round((100 * event.loaded) / event.total);
      setUploadProgress(progress);
    },
  };

  const handleUploadDocument = (e) => {
    let target = e.target.files;
    let arr = additionalDocuments;
    let length = getUniqueId();
    const formData = new FormData();
    formData.append("type", 'Promotion');
    formData.append("attachments", target[0]);
    if(id != "null") {
      formData.append("id", id);
    }
    setApiRequestOngoing(true)
    let apiUrl = id !="null" ? "https://sglandbev1.demopsts.com/public/api/EditGeneralupload" : "https://sglandbev1.demopsts.com/public/api/Generalupload"
    axios
    .post(apiUrl, formData, config)
    .then((response) => {
      console.log("File uploaded successfully", response.data);
      if(response?.data?.response_status == "success") {
      arr.push({
          asset_id: response?.data?.response_json?.id,
          fileName: target[0]?.name,
          size: target[0]?.size,
          file: target[0],
          type: target[0]?.type,
          src: URL.createObjectURL(target[0]),
          new_filename: response?.data?.response_json?.url
      })
      setAdditionalDocuments([...arr])
      const fileInput = document.getElementById("file_input");
      fileInput.value = "";
      setUploadErrorMsg("")
    } else if(response?.data?.response_status == "fail") {
      setUploadErrorMsg(response?.data?.response_message)
    }
    setApiRequestOngoing(false)
    setUploadProgress(0)
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
      if(error?.data?.response_status == "fail") {
        setUploadErrorMsg(error?.data?.response_message)
      }
      setApiRequestOngoing(false)
      setUploadProgress(0)
    });

  };


  const handleDeleteFiles = (id) => {
    console.log("additionalDocuments ",additionalDocuments)
    let removedFiles = additionalDocuments?.filter(item => item?.asset_id !== id)
    console.log("removedFiles ",removedFiles, 'id ',id)
    setAdditionalDocuments(removedFiles)
  }

  console.log("addiio ",additionalDocuments)

  const handleCopy = (id, new_filename) => {
    navigator.clipboard.writeText(new_filename);
    setTooltipId(id);
    setTimeout(() => {
      setTooltipId(null);
    }, 8000);
  };
  
  const handleCheckBox = (name) => {
    setEventData((prev) => ({
      ...prev,
      [name]: !eventData[name],
    }));
  };

  const handleMobileInput = (data, country, event) => {
    const { name, value } = event.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
      mobileCode: country.dialCode,
      mobileNumber: data.slice(country.dialCode.length),
    }));
  };
  const handleInput = (e, fieldName) => {
    if (fieldName == "terms_conditions" || fieldName == "description") {
      setEventData((prev) => ({ ...prev, [fieldName]: e }));
      setError({
        ...error,
        [fieldName]: "",
      });
      return;
    }
    const { name, value } = e.target;
    if (name === "property" && value === 1) {
      setEventData((prev) => ({ ...prev, [name]: value }));
      return;
    }
    setEventData((prev) => ({ ...prev, [name]: value }));
    setError({
      ...error,
      [name]: "",
    });
  };

  const getDescriptionAsInnerText = (nodeString) => {
    let spanTag = document.createElement('span');
    spanTag.innerHTML = nodeString
    return spanTag?.textContent || spanTag?.innerText;
  }


  const IOSSwitch = styled((SwitchProps) => (
    <Switch
      checked={isToggle}
      onChange={handleToggleChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...SwitchProps}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#123FA9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const getPopMessage = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
          }}
        >
          <StyledTypography>Does this appear to the user as a popup?</StyledTypography>
          <Box>
          <IOSSwitch defaultValue={false} />
          </Box>
        </Box>
      </>
    );
  };
  

  const getplatformScren = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
          }}
        >
          <StyledTypography>Platform</StyledTypography>
          <Box>
            <Checkbox
              icon={<CheckboxIcon />}
              checked={eventData?.app == true ? true : false}
              onClick={() => {
                handleCheckBox("app");
                setError({
                  ...error,
                  platform: "",
                });
              }}
              sx={{ display: "inline" }}
            />
            <Typography
              sx={{ display: "inline", color: "#0F2C6F", fontSize: "12px" }}
            >
              App
            </Typography>
          </Box>
          <Box>
            <Checkbox
              icon={<CheckboxIcon />}
              checked={eventData?.dashboard == true ? true : false}
              onClick={() => {
                handleCheckBox("dashboard");
                setError({
                  ...error,
                  platform: "",
                });
              }}
              sx={{ display: "inline" }}
            />
            <Typography
              sx={{ display: "inline", color: "#0F2C6F", fontSize: "12px" }}
            >
              Dashboard
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ color: "red", fontSize: "14px" }}>
          {error?.platform}
        </Typography>
      </>
    );
  };
  const handleWeeKdaysSelection = (selectedIndex) => {
    let updateData = [];
    dailyDatas.forEach((element) => {
      if (element.id === selectedIndex) {
        updateData.push({ ...element, isDisable: !element.isDisable });
      } else {
        updateData.push({ ...element });
      }
    });
    setDailyDatas([...updateData]);

    const isSameHoursChecked = getSameHours(updateData);
    if (isSameHoursChecked == true) {
      setEventData((prev) => ({
        ...prev,
        sameHoursApplied: true,
      }));
    } else {
      setEventData((prev) => ({
        ...prev,
        sameHoursApplied: false,
      }));
    }
  };
  const handleSameHours = (toSet) => {
    setEventData((prev) => ({ ...prev, [toSet]: !eventData.sameHoursApplied }));
    if (!eventData.sameHoursApplied) {
      let updateData = [];
      dailyDatas.forEach((element) => {
        updateData.push({ ...element, isDisable: true });
      });
    }
  };

  const clickContinue = () => {
    if (tabValue == 0) {
      setTabValue(1);
    } else if (tabValue == 1) {
      handleContinueButton();
    }
  };

  useEffect(() => {
    setIsDailyDataEmpty(dailyDatas?.every((item) => item.FromTime == ""));
    const filData = dailyDatas?.filter((item) => item.isDisable == false);
    setFilterDailyData(filData);
    const filDataFromTime = filData && filData[0]?.FromTime;
    const filDataToTime = filData && filData[0]?.toTime;
    const filtData = filData?.every(
      (item) => item.FromTime == filDataFromTime && item.toTime == filDataToTime
    );
    setIsFilterDailyData(filtData);
  }, [dailyDatas]);

  useEffect(() => {
    const descriptionText = document.getElementById("description");
    setDescriptionLength(descriptionText?.innerText.length);
    const termsAndConditionText = document.getElementById("termsandcondition");
    setTermsAndConditionLength(termsAndConditionText?.innerText.length);
  }, [eventData.description, eventData.terms_conditions]);

  const renderNewEvent = (eventData) => {
    return (
      <Grid rowSpacing={2} container sx={{ backgroundColor: "white", p: 2 }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              color: "#0F2C6F",
              fontFamily: "Inter",
            }}
          >
            Property Details
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#6F80A9",
              fontFamily: "Inter",
            }}
          >
            Please provide the restaurant details below like time, date and
            location.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              fontFamily: "Inter",
            }}
          >
            Property*
          </Typography>
          <Select
            sx={{ width: 300, my: 1 }}
            name="property"
            value={Number(eventData?.property)}
            onChange={(e) => handleInput(e)}
          >
            {promotionProperties?.map((value) => (
              <MenuItem
                sx={{ color: "#0F2C6F", maxWidth: 600 }}
                value={value.property_id}
              >
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {eventData.property == 2 && (
          <Grid item xs={12}>
            <Typography
              sx={{ color: " #0F2C6F", fontSize: "14px", fontWeight: 550 }}
            >
              Tenant*
            </Typography>
            <Select
              value={savedPropertiesId}
              sx={{ width: 400, my: 1 }}
              name="savedProperties"
              onChange={(e) => {
                setSavedPropertiesId(e.target.value);
                setError({
                  ...error,
                  tenant: "",
                });
              }}
            >
              {savedPropertiesData?.map((value) => (
                <MenuItem sx={{ color: "#0F2C6F" }} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{ color: "red" }}>{error.tenant}</Typography>
          </Grid>
        )}
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              fontFamily: "Inter",
              mb: 1,
            }}
          >
            Name*
          </Typography>
          <TextField
            disabled={eventData.property == 2 ? true : false}
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            name="name"
            value={eventData.name ? eventData.name : ""}
            onChange={(e) => handleInput(e)}
            inputProps={{ maxLength: 1000 }}
          />
          <Typography sx={{ color: "red", fontSize: "14px" }}>
            {error.name}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }} />
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              fontFamily: "Inter",
              mb: 1,
            }}
          >
            Email / Contact
          </Typography>
          <TextField
            multiline
            fullWidth
            id="outlined-basic"
            disabled={eventData.property == 2 ? true : false}
            label=""
            variant="outlined"
            name="contact_email"
            inputProps={{ maxLength: 300 }}
            value={eventData.contact_email ? eventData.contact_email : ""}
            onChange={(e) => handleInput(e)}
          />
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            {error?.contact_email}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1, pl: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              fontFamily: "Inter",
              mb: 1,
            }}
          >
            Contact Number
          </Typography>
          <PhoneInput
            inputStyle={{
              width: "90%",
              height: "56px",
              borderRadius: "6px",
              color: "#0F2C6F",
            }}
            country="sg"
            onChange={(data, country, event) =>
              handleMobileInput(data, country, event)
            }
            value={eventData?.mobile}
            disabled={eventData.property == 2 ? true : false}
            inputProps={{ name: "mobile" }}
          />
          <Typography sx={{ color: "red", fontSize: "14px" }}>
            {error?.number}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              fontFamily: "Inter",
              mb: 1,
            }}
          >
            Address
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            disabled={eventData.property == 2 ? true : false}
            label=""
            variant="outlined"
            name="address"
            value={eventData.address ? eventData.address : ""}
            onChange={(e) => handleInput(e)}
            multiline
            minRows={3}
            maxRows={5}
          />
          <FormControlLabel
            sx={{ color: "#0F2C6F" }}
            control={
              <Checkbox
                icon={<CheckboxIcon />}
                checked={eventData?.featureEnabled == true ? true : false}
                name="futurePost"
                onClick={() => handleCheckBox("featureEnabled")}
              />
            }
            label="Save Property for future post"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#0F2C6F",
              fontFamily: "Inter",
            }}
          >
            Opening Hours
          </Typography>
          <FormControlLabel
            sx={{ color: "#0F2C6F" }}
            control={
              <Checkbox
                icon={<CheckboxIcon />}
                checked={eventData?.sameHoursApplied == true ? true : false}
                name="everyDayHours"
                onClick={() => handleSameHours("sameHoursApplied")}
              />
            }
            label="Same hours everyday"
          />
        </Grid>
        {dailyDatas?.map((item, index) => (
          <Grid
            xs={12}
            sx={{ mt: 3, display: "flex", justifyContent: "start" }}
          >
            <Grid>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "550",
                  color: "#0F2C6F",
                  fontFamily: "Inter",
                  textTransform: "capitalize",
                }}
              >
                {item.day}
              </Typography>
              <FormControlLabel
                sx={{ color: "#0F2C6F" }}
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checked={item.isDisable}
                    name={item.day}
                    onClick={() => handleWeeKdaysSelection(item.id)}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    Closed Today
                  </Typography>
                }
              />
            </Grid>
            <Grid sx={{ ml: 2 }}>
              <Typography
                sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 550 }}
              >
                From
              </Typography>
              <Select
                sx={{ width: 100 }}
                MenuProps={MenuProps}
                disabled={item.isDisable}
                value={item.isDisable ? "" : item.FromTime}
                onChange={(newValue) =>
                  handleDailyTime(newValue, `FromTime`, item.id)
                }
              >
                {startHours?.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
              {/* <TextField disabled={item.isDisable}  type="time" value={item.isDisable ? '' :item.FromTime}  onChange={(newValue) => handleDailyTime(newValue, `FromTime`,item.id)} /> */}

              <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
                <br /> {error[`eventStartTime${item.day}`]}
              </Typography>
            </Grid>
            <Grid sx={{ ml: 2 }}>
              <Typography
                sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 550 }}
              >
                To
              </Typography>
              <Select
                sx={{ width: 100 }}
                MenuProps={MenuProps}
                disabled={item.isDisable}
                value={item.isDisable ? "" : item.toTime}
                onChange={(newValue) =>
                  handleDailyTime(newValue, `toTime`, item.id)
                }
              >
                {startHours?.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
              {/* <TextField disabled={item.isDisable} type="time" value={item.isDisable ? '' : item.toTime}   onChange={(newValue) => handleDailyTime(newValue, `toTime`,item.id)}/> */}

              <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
                <br /> {error[`eventEndTime${item.day}`]}
              </Typography>
              <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
                {error[`validate${item.day}`]}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Divider sx={{ mb: 3 }} />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#0F2C6F",
              fontFamily: "Inter",
              mb: 1,
            }}
          >
            Key Highlight Details
          </Typography>
        </Grid>
        <Grid xs={6} sx={{ mt: 1, display: "flex" }}>
          <Grid>
            <Typography
              sx={{ color: " #0F2C6F", fontSize: "14px", fontWeight: 550 }}
            >
              Start Date
            </Typography>
            <DesktopDatePicker
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              // disablePast
              inputFormat="dd/MM/yyyy"
              value={eventData?.eventStartDate}
              onChange={(newValue) => handleDate(newValue, "eventStartDate")}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
              {error?.eventStartDate}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 2 }}>
            <Typography
              sx={{ color: " #0F2C6F", fontSize: "14px", fontWeight: 550 }}
            >
              End Date
            </Typography>
            <DesktopDatePicker
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              // disablePast
              minDate={eventData?.eventStartDate || new Date()}
              inputFormat="dd/MM/yyyy"
              value={eventData?.eventEndDate}
              onChange={(newValue) => handleDate(newValue, "eventEndDate")}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
              {error?.eventEndDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "550", color: "#0F2C6F" }}
          >
            Title*
          </Typography>
          <TextField
            fullWidth
            inputProps={{ maxLength: 200 }}
            id="outlined-basic"
            label=""
            variant="outlined"
            name="title"
            value={eventData.title}
            onChange={(e) => handleInput(e)}
          />
          <Typography variant="p" sx={{ fontSize: "14px", color: "#0F2C6F" }}>
            {eventData?.title?.length || 0}/200
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            <br />
            {error?.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box sx={{ mb: 6 }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "550", color: "#0F2C6F" }}
            >
              Description*
            </Typography>
            {/* <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        name='description'
                        value={eventData.description}
                        onChange={(e) => handleInput(e)}
                        inputProps={{ maxLength: 3000 }}
                    /> */}
            {/* <textarea maxLength={3000} style={{minWidth:'100%',maxWidth:'100%',height:80,fontSize:'15px',border:'1px solid #DBDFE9' }} name='description'
                        value={eventData.description}
                        onChange={(e) => handleInput(e)} 
                    /> */}
            <ReactQuill
              value={eventData?.description}
              name="description"
              onChange={(e) => handleInput(e, "description")}
              modules={module}
              theme="snow"
              style={{ height: "150px" }}
              placeholder="description"
            />
          </Box>
          <Typography variant="p" sx={{ fontSize: "14px", color: "#0F2C6F" }}>
            {/* {descriptionLength || 0}/5000 */}
            <p
              id="description"
              style={{ display: "none" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(eventData?.description),
              }}
            />
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            <br />
            {error?.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          {/* <Divider sx={{ mb: 1, mt: 1 }} /> */}
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Terms & Conditions
          </Typography>

          {/* commented on 02/11/23 <textarea maxLength={3000} style={{minWidth:'100%',maxWidth:'100%',height:80,fontSize:'15px',border:'1px solid #DBDFE9'}} value={eventData.terms_conditions}
                        name="terms_conditions" onChange={(e) => handleInput(e)} /> */}

          <Box sx={{ mb: 6 }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "550",
                color: "#0F2C6F",
                mt: 2,
              }}
            >
              Description
            </Typography>
            {/* <Typography sx={{color:'#0F2C6F',fontSize:'18px',fontWeight:500, mb:3}}>Terms & Conditions</Typography> */}
            <ReactQuill
              value={eventData?.terms_conditions}
              name="terms_conditions"
              onChange={(e) => handleInput(e, "terms_conditions")}
              modules={module}
              theme="snow"
              style={{ height: "150px" }}
              placeholder="Terms & Conditions"
            />
          </Box>
          {/* <TextField fullWidth id="outlined-basic"
                        label=""
                        variant="outlined"
                        multiline
                        minRows={3}
                        maxRows={5}
                        value={eventData.terms_conditions}
                        name="terms_conditions" onChange={(e) => handleInput(e)}
                        inputProps={{ maxLength: 3000 }}
                    /> */}
          <Typography variant="p" sx={{ fontSize: "14px", color: "#0F2C6F" }}>
            {/* {termsAndConditionLength || 0}/5000 */}
            <p
              id="termsandcondition"
              style={{ display: "none" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(eventData?.terms_conditions),
              }}
            />
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            <br /> {error?.terms_conditions}
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
            >
              Add Cover Image*{" "}
              <span style={{ fontSize: "10px", color: "#0F2C6F" }}>
                ( maximum 1 images)
              </span>
            </Typography>
            <Box>
              {coverImage.length < 1 && (
                <Button
                  component="label"
                  variant="outlined"
                  sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize" }}
                  endIcon={<UploadIcon />}
                >
                  Upload
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleUploadButton(e)}
                  />
                </Button>
              )}
            </Box>
          </Box>

          {uploadedImg && !submitBool && (
            <Cropper
              key={cropperKey} // Re-render Cropper when the key changes
              src={uploadedImg}
              style={{ height: 225, width: 400 }}
              // Cropper.js options
              // initialAspectRatio={0}
              aspectRatio={1 / 1}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
              minCropBoxWidth={150}
              minCropBoxHeight={150}
            />
          )}
          {uploadedImg && !submitBool && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "400px",
                mt: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setSubmitBool(false);
                  setCoverImage([]);
                  setIsCroppedSubmit(false);
                  setUploadedImg(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setSubmitBool(true);
                  setIsCroppedSubmit(true);
                  setUploadedImg(croppedImg);
                }}
              >
                Submit
              </Button>
            </Box>
          )}
          {uploadedImg != null && submitBool && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                width: "135px",
                my: 2,
              }}
            >
              <Box
                component="img"
                src={!isCroppedSubmit ? croppedImg : uploadedImg}
                alt="previewImg"
                sx={{
                  height: "135px",
                  borderRadius: "8px",
                  objectFit: "contain",
                }}
              />
              <Box sx={{ position: "absolute", right: "-13px", top: "-15px" }}>
                <CropClose
                  style={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setCoverImage([]);
                    setIsCoverImageRemoved(true);
                    setIsCroppedSubmit(false);
                    setUploadedImg(null);
                    setSubmitBool(false);
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
        <Typography sx={{ color: "red", fontSize: "14px" }}>
          {error?.coverImage}
        </Typography>
        {submitBool == false &&
          uploadedImg == null &&
          coverImage.length > 0 &&
          coverImage.map((item, index) => (
            <Box
              item
              sx={{
                width: "135px",
                height: "135px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div
                style={{ position: "absolute", right: "-13px", top: "-15px" }}
              >
                <CropClose
                  style={{
                    width: "21.22px",
                    height: "21.22px",
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setCoverImage([]);
                    setIsCoverImageRemoved(true);
                  }}
                />
              </div>
              <Box
                key={index}
                component="img"
                src={item.src}
                sx={{
                  width: "135px",
                  height: "135px",
                  // background: `url(${item.src})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  borderRadius: "5px",
                }}
              />
            </Box>
          ))}
        <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Add Additional Images
            <span style={{ fontSize: "10px", color: "#0F2C6F" }}>
              {" "}
              (optional) (maximum 5 images)
            </span>
          </Typography>
          {aditionalImages.length < 5 && (
            <Button
              component="label"
              variant="outlined"
              sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize" }}
              endIcon={<UploadIcon />}
            >
              Upload
              <input
                multiple
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleUploadMultipleButton(e)}
              />
            </Button>
          )}
        </Grid>
        {aditionalImages.length > 0 &&
          aditionalImages.map((item, index) => (
            <Grid
              item
              sx={{
                p: 1,
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", right: "0", top: "5px" }}>
                <CropClose
                  style={{
                    width: "21.22px",
                    height: "21.22px",
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => removeAditionalImages(index)}
                />
              </div>

              <Box
                key={index}
                component="img"
                src={item.src}
                sx={{
                  width: "100px",
                  height: "100px",
                  maxWidth: "100px",
                  maxHeight: "100px",
                  // background:`url(${item.src})` ,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  borderRadius: "5px",
                }}
              />
            </Grid>
          ))}
        {error.additionalImages && (
          <Grid
            item
            xs={12}
            sx={{
              p: 1,
              mt: 1,
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
              <br /> {error?.additionalImages}
            </Typography>
          </Grid>
        )}
      <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "space-between", width:"100%" }}
        >
          <Box sx={{display:"flex", flexDirection:"column", }} >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Add Documents
            <span style={{ fontSize: "10px", color: "#0F2C6F", textOverflow:"clip"}}>
              {" "}
              (optional) 
            </span>
          </Typography>
          <span style={{fontSize: "10px", color: "#0F2C6F",}}> (Note - allowed types:  jpg,jpeg,png,svg,webp,doc,docx,xls,xlsx,ppt,pptx,txt,mp4,pdf)</span>
          </Box>
        
       
            <Button
              component="label"
              variant="outlined"
              disabled={apiRequestOngoing}
              sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize", }}
              endIcon={<UploadIcon />}
            >
              Upload
              <input
                capture
                id="file_input"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleUploadDocument(e)}
              />
            </Button>
        </Grid>     
        <Box sx={{ display: "flex", flexDirection: "column", width:"100%" }}>
           
            {additionalDocuments?.length > 0 && (
              <Box>
                {additionalDocuments?.map((value) => (
                  <Box
                    key={value?.asset_id} // Add a unique key for each item
                    sx={{
                      display: 'flex',
                      alignItems: "center",
                      justifyContent: 'space-between',
                      gap: '5px',
                      border: '1px solid #DBDFE9',
                      borderRadius: "8px",
                      mt: 2,
                      p: 1,
                    }}
                  >
                    <Box sx={{display:"flex", alignItems:"center",gap:"5px", height:"54.8px", width:"100%"}}>
                      <Box>
                        {getFileIcon(value?.fileName ? value?.fileName : value?.orig_filename)}

                      </Box>
                      {/* <Box sx={{display:"flex", flexDirection:"column",  width:"100%", height:"90%"}}> */}
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: '14px',
                          maxWidth: "500px",
                          wordWrap: "break-word", // Allows text wrapping when max width is exceeded
                          flexGrow: 1, // Takes up remaining space on the left
                        }}
                      >
                        {value?.fileName ? value?.fileName : value?.orig_filename}
                        <span style={{ color: "#9AA6C3", fontSize: '12px', marginLeft: '10px' }}>
                          ({Math.round(value?.size ? value?.size / 1000 : value?.filesize / 1000)}KB)
                        </span>
                      </Typography>
                      {/* <BorderLinearProgress variant="determinate" value={uploadProgress} />
                      </Box> */}
                    </Box>

                

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Tooltip
                        open={tooltipId === value?.asset_id}
                        title="Copied to clipboard"
                        placement="top"
                        onClose={() => setTooltipId(null)}
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 8],
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "black",
                              color: "white",
                              fontSize: 12,
                              p: 1,
                            },
                          },
                        }}
                      >
                        <IconButton onClick={() => handleCopy(value?.asset_id, value?.new_filename)}>
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                        <DeleteIcon style={{width:"40px", height:"40px", cursor:"pointer"}} onClick={() => handleDeleteFiles(value?.asset_id)}/>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
              {apiRequestOngoing && <Stack spacing={2} sx={{ flexGrow: 1, border:"1px solid #DBDFE9", borderRadius:"8px", mt:2, p:2, pb:'8px' }}>
                        <BorderLinearProgress variant="determinate" value={uploadProgress} />
                        <span>{uploadProgress}%</span>
                      </Stack>}
                {uploadErrorMsg && <Typography variant="p" sx={{ fontSize: "14px", color: "red", mt:4 }}>{uploadErrorMsg}</Typography>}
            
          </Box>
      </Grid>
    );
  };

  const renderNewPreview = () => {
    return (
      <>
        <Grid container sx={{ backgroundColor: "#FFFFFF", p: 2, pb: "24px",  maxHeight:"750px", overflow:"auto" }}>
          <Grid item xs={12}>
            <PrimaryTypography>Application Preview</PrimaryTypography>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%", flexWrap:"wrap" }}
          >
            <Grid item sx={{ width: "320px" }}>
              <Box
                sx={{
                  height: "600px",
                  width: "320px",
                  position: "relative",
                  borderRadius: "8px",
                  border: "1px solid #F3F4F8",
                  mt: 2,
                  backgroundColor: "#F3F4F8",
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ fontSize: "15px" }}>
                    {moment(new Date()).local().format("HH:mm")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <WifiIcon style={{ fontSize: "15px" }} />
                    <SignalCellularAltIcon
                      style={{ fontSize: "15px", mx: 1 }}
                    />
                    <BatteryCharging50Icon style={{ fontSize: "15px" }} />
                  </Box>
                </Box>
                <Box sx={{ position: "absolute", top: "77%", width: "100%" }}>
                  <Box
                    sx={{
                      backgroundColor: "#ccf2ff",
                      width: "100%",
                      borderRadius: "12px 12px 0 0",
                    }}
                  >
                    <PrimaryTypography
                      textAlign={"center"}
                      sx={{ py: 2, textAlign: "left", ml: "10px" }}
                    >
                      Key Highlights
                    </PrimaryTypography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      p: 1,
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "70px",
                        minHeight: "70px",
                        borderRadius: "8px",
                        backgroundPosition: "center",
                        background: `url(${coverImage[0]?.src})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        ml: "14px",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "#0F2C6F",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          fontFamily: "Inter",
                          fontWeight: "600",
                        }}
                      >
                        {eventData?.title}
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            color: "#0F2C6F",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "500!important",
                          }}
                        >
                          {eventData?.eventStartDate != null &&
                            moment(eventData?.eventStartDate).format(
                              "DD MMM YYYY"
                            ) +
                              " - " +
                              eventData?.eventEndDate !=
                              null &&
                            moment(eventData?.eventEndDate).format(
                              "DD MMM YYYY"
                            )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#0F2C6F",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontWeight: "400!important",
                          }}
                        >
                          {eventData?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sx={{ ml: 4, maxWidth: "320px" }}>
              <Box
                sx={{
                  height: "600px",
                  overflowX: "hidden",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": { display: "none" },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid #F3F4F8",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    height: "310px",
                    width: "320px",
                    backgroundImage: `url(${coverImage[0]?.src})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography style={{ fontSize: "13px", color: "#FFFFFF" }}>
                      {moment(new Date()).local().format("HH:mm")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <WifiIcon
                        style={{ fontSize: "15px", color: "#FFFFFF" }}
                      />
                      <SignalCellularAltIcon
                        style={{ fontSize: "15px", mx: 1, color: "#FFFFFF" }}
                      />
                      <BatteryCharging50Icon
                        style={{ fontSize: "15px", color: "#FFFFFF" }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "0%",
                      overflowWrap: "anywhere!important",
                      width: "100%",
                      backgroundImage:
                        "linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.6),rgba(0,0,0,0.8))",
                    }}
                  >
                    <Typography
                      sx={{
                        ml: "16px",
                        color: "#FFFFFF",
                        fontSize: "18px",
                        wordWrap: "break-word",
                        fontFamily: "Inter",
                        fontWeight: "300 !important",
                        overflowWrap: "anywhere!important",
                      }}
                    >
                      {eventData?.title}
                    </Typography>
                    <Typography
                      sx={{
                        ml: "16px",
                        color: "#FFFFFF",
                        width: "90%",
                        fontWeight: "150",
                        fontSize: "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontFamily: "Inter",
                        marginTop: "5px",
                        marginBottom: "14px",
                      }}
                    >
                      {eventData?.name || ""}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ px: 2 }}>
                  <SecondaryTypography
                    sx={{
                      whiteSpace: "pre-line",
                      wordBreak: "break-word",
                      lineHeight: "24px",
                      margin: "0!important",
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(eventData?.description),
                      }}
                    />
                  </SecondaryTypography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      mb: 2,
                      gap: "5px",
                      overflow: "auto",
                      "&::-webkit-scrollbar": { display: "none" },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                  >
                    {aditionalImages?.map((value) => (
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "113px",
                          maxHeight: "90px",
                          minHeight: "90px",
                          borderRadius: "8px",
                          backgroundRepeat: "no-repeat",
                          ml: 0.2,
                          backgroundSize: "contain",
                        }}
                        src={value?.src}
                      ></Box>
                    ))}
                  </Box>
                  <Box>
                    {/* <SecondaryTypography sx={{wordBreak:"break-word",whiteSpace:"pre-line"}}>{eventData?.description}</SecondaryTypography> */}

                    <PrimaryTypography
                      sx={{
                        my: "5px",
                        display: termsAndConditionLength > 0 ? "block" : "none",
                      }}
                    >
                      CONDITIONS
                    </PrimaryTypography>
                    {/* <SecondaryTypography sx={{whiteSpace:'pre-line',wordBreak:"break-word"}}>{eventData?.terms_conditions}</SecondaryTypography> */}
                    <SecondaryTypography
                      sx={{
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        lineHeight: "24px",
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            eventData?.terms_conditions
                          ),
                        }}
                      />
                    </SecondaryTypography>
                    {/* <PrimaryTypography sx={{my:'5px'}}>Open Hours</PrimaryTypography> */}
                  </Box>

                  <Box>
                    <PrimaryTypography
                      sx={{
                        my: 1,
                        mt:2,
                        display: termsAndConditionLength > 0 ? "block" : "none",
                      }}
                    >
                      EXTERNAL LINKS
                    </PrimaryTypography>
                      <ol style={{color:"#0F2C6F"}}>
                         {additionalDocuments?.map ((item,index) => (
                            <a href={item?.src} target="_blank" style={{textDecoration:"none", cursor:"pointer",color:"#2d2dcd", lineHeight:"30px", fontWeight:600}}><li key={index}>{item?.fileName ? item?.fileName : item?.orig_filename}</li></a>
                         ))}
                      </ol>
                  </Box>

                  <Box
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(143, 146, 161,0.05)",
                      mt: 2,
                    }}
                  >
                    <Box sx={{ display: isDailyDataEmpty ? "none" : "block" }}>
                      {!isFilterDailyData ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "38px",
                            my: 1,
                            p: 1,
                          }}
                        >
                          {/* <Box sx={{display: 'flex',flex:1}}> */}
                          <Box sx={{ display: "flex", mr: 1 }}>
                            <Clock
                              style={{
                                height: "16px",
                                width: "18px",
                                marginTop: "2px",
                              }}
                            />
                            <SecondaryTypography sx={{ ml: 1 }}>
                              Hours
                            </SecondaryTypography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              gap: "10px",
                            }}
                          >
                            {dailyDatas?.map((data, index) => (
                              <SecondaryTypography
                                sx={{
                                  display: data?.isDisable ? "none" : "block",
                                }}
                                key={index}
                              >
                                <Typography
                                  sx={{
                                    display: "inline-block",
                                    width: "40px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data?.day?.slice(0, 3)}{" "}
                                </Typography>
                                {data?.submitFromTime == null
                                  ? "-"
                                  : data?.submitFromTime +
                                    " - " +
                                    data?.submittoTime}
                              </SecondaryTypography>
                            ))}
                          </Box>
                          {/* </Box> */}
                        </Box>
                      ) : (
                        filterDailyData?.length > 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              my: 1,
                              p: 1,
                            }}
                          >
                            <Box sx={{ display: "flex", flex: 1, gap: "38px" }}>
                              <Box sx={{ display: "flex", mr: 1 }}>
                                <Clock
                                  style={{
                                    height: "16px",
                                    width: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                <SecondaryTypography sx={{ ml: 1 }}>
                                  Hours
                                </SecondaryTypography>
                              </Box>
                              <Box sx={{ flex: 1 }}>
                                <SecondaryTypography>
                                  {filterDailyData[0]?.submitFromTime == null
                                    ? "-"
                                    : filterDailyData[0]?.submitFromTime +
                                      " - " +
                                      filterDailyData[0]?.submittoTime}
                                </SecondaryTypography>
                              </Box>
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                    {/* <Box sx={{display:"flex",justifyContent:'flex-start',p:1, gap:'24px'}}>
                                <Box sx={{display:'flex',mr:1,position:'relative'}}>
                                    <img style={{position:'absolute',top:'0%',  height:'18px', width:'18px'}} src={Location} />
                                    <SecondaryTypography sx={{ml:3}}>Location</SecondaryTypography>
                                </Box>
                                <SecondaryTypography sx={{width:'90%',overflow:"hidden",wordWrap:'break-word',textOverflow:'clip'}}>{eventData?.location}</SecondaryTypography>
                            </Box> */}
                    {/* <Box sx={{display:"flex",justifyContent:'flex-start', gap:'40px', mt:1,p:1}}>
                                <Box sx={{display:'flex',mr:1}}>
                                    <Clock style={{ height:'16px', width:'18px', marginTop:'2px'}} />
                                    <SecondaryTypography sx={{ml:1}}>Hours</SecondaryTypography>
                                </Box>
                                <SecondaryTypography>{eventData?.eventStartTime +' - ' +eventData?.eventEndTime}</SecondaryTypography>
                            </Box> */}
                    {/* <Box sx={{display:"flex",justifyContent:'space-between',my:1}}>
                                <Box sx={{display:'flex'}}>
                                    <img src={Clock} />
                                    <Typography sx={{ml:1}}>Hours</Typography>
                                </Box>
                                <Box></Box>
                            </Box>  */}
                    {eventData?.mobile != null &&
                      eventData?.mobile != "" &&
                      eventData?.mobile != "--" &&
                      eventData?.mobile != "undefinedundefined" &&
                      eventData?.mobile != "nullnull" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "28px",
                            my: 1,
                            p: 1,
                          }}
                        >
                          <Box sx={{ display: "flex", mr: 1 }}>
                            <img
                              style={{
                                alignSelf: "flex-start",
                                height: "18px",
                                width: "14px",
                              }}
                              src={Phone}
                            />
                            <SecondaryTypography sx={{ ml: 1 }}>
                              Contact
                            </SecondaryTypography>
                          </Box>
                          <SecondaryTypography>
                            {eventData?.mobile}
                          </SecondaryTypography>
                        </Box>
                      )}
                    {eventData?.contact_email != "-" &&
                      eventData?.contact_email != undefined &&
                      eventData?.contact_email != "" &&
                      eventData?.contact_email != null && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "40px",
                            mt: 1,
                            p: 1,
                            overflowWrap: "break-word!important  ",
                          }}
                        >
                          <Box sx={{ display: "flex", mr: 1 }}>
                            <img
                              style={{ height: "15px", width: "15px" }}
                              src={Email}
                            />
                            <SecondaryTypography sx={{ ml: 1 }}>
                              Email
                            </SecondaryTypography>
                          </Box>
                          <SecondaryTypography
                            sx={{ overflowWrap: "anywhere!important" }}
                          >
                            {eventData?.contact_email}
                          </SecondaryTypography>
                        </Box>
                      )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            {isToggle && <Grid item sx={{ width: "320px", ml: 4, mt: isWrap ? 4 : 0 }}>
              <Box
                sx={{
                  height: "600px",
                  width: "320px",
                  position: "relative",
                  borderRadius: "8px",
                  border: "1px solid #F3F4F8",
                  mt: 2,
                  backgroundColor: "#F3F4F8",
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ fontSize: "15px" }}>
                    {moment(new Date()).local().format("HH:mm")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <WifiIcon style={{ fontSize: "15px" }} />
                    <SignalCellularAltIcon
                      style={{ fontSize: "15px", mx: 1 }}
                    />
                    <BatteryCharging50Icon style={{ fontSize: "15px" }} />
                  </Box>
                </Box>

             
                
                <Box sx={{ position: "absolute", bottom: "0", width: "100%", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);", borderTopLeftRadius:"24px", borderTopRightRadius:"24px" }}> 
                <Box sx={{display:"flex", justifyContent:"center", mt:'-20px', width:"100%", mb:1}}>
                  <Typography sx={{ width:"72px", height:"5px", borderRadius:"100px", backgroundColor:"#DBDFE9"}} />
                </Box> 
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      p: 1,
                      justifyContent: "center",
                      borderTopLeftRadius:"24px",
                      borderTopRightRadius:"24px",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "200px",
                        minHeight: "200px",
                        borderRadius: "6px",
                        background: `url(${coverImage[0]?.src})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        display:"flex",
                        justifyContent:"center",
                        position: "relative",
                        mt:2
                      }}
                    >
                      <Box
                      sx={{
                        position: "absolute",
                        bottom: "0%",
                        // px: 2,
                        overflowWrap: "anywhere!important",
                        width: "100%",
                        backgroundImage:
                          "linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.6),rgba(0,0,0,0.8))",
                          borderRadius:"6px",
                          p:"10px 0"
                      }}
                    >
                      <Typography
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#fff",
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            letterSpacing:"-0.28px",
                            lineHeight:"20px",
                            marginLeft:"12px",
                            marginRight:"12px"
                          }}
                        >
                          {eventData?.title}
                        </Typography>
                        <Box sx={{px:"12px"}}>
                          <Typography
                            sx={{
                              color: "#FFF",
                              fontSize: "10px",
                              fontFamily: "Inter",
                              fontWeight: "400!important",
                            }}
                          >
                            {eventData?.eventStartDate && eventData?.eventEndDate
                              ? `${moment(eventData.eventStartDate).format(
                                  "DD MMM"
                                )}  ${ eventData?.eventStartTime || ''} - ${moment(eventData.eventEndDate).format(
                                  "DD MMM"
                                )} ${
                                  eventData?.eventEndTime || ''}`
                              : ""}
                          </Typography>

                          {/* <Typography
                            sx={{
                              color: "#FFF",
                              fontSize: "12px",
                              fontFamily: "Inter",
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight: "400!important",
                            }}
                          >
                            {eventData?.eventStartTime && eventData?.eventEndTime
                              ? `${moment(
                                  eventData.eventStartTime,
                                  "HH:mm"
                                ).format("h:mm A")} - ${moment(
                                  eventData.eventEndTime,
                                  "HH:mm"
                                ).format("h:mm A")}`
                              : eventData?.eventStartTime
                              ? moment(eventData.eventStartTime, "HH:mm").format(
                                  "h:mm A"
                                )
                              : ""}
                          </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{width:"100%", backgroundColor:"white"}}>
                    <Box sx={{display:"flex", flexDirection:"column", gap:2, backgroundColor:"white", width:"90%", margin:"0 auto"}}>
                          <Typography
                            sx={{
                              textAlign:"center",
                              textOverflow: "ellipsis",
                              color: "#02194D",
                              fontSize: "18px",
                              fontFamily: "Inter",
                              fontWeight: "500",
                              letterSpacing:"-0.25px",
                              lineHeight:"24px",
                              p:"0 10px",
                              mt:"8px"
                            }}
                          >
                            New Highlight Available
                          </Typography>
                          <Typography
                          sx={{fontSize:"14px"}}
                            style={{
                              textAlign:"center",
                              color: "#02194D",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              fontWeight: "400",
                              letterSpacing:"-0.25px",
                              lineHeight:"20px",
                              WebkitBoxOrient:"vertical", 
                              WebkitLineClamp:2, 
                              display:" -webkit-box",
                              overflow:"hidden",
                              p:"0 10px"
                            }}
                          >
                            {eventData?.description ? getDescriptionAsInnerText(eventData?.description) : ""}
                          </Typography>    
                          <Button sx={{p:"16px 40px", height:"56px",borderRadius:"40px",color:"white", textTransform:"Capitalize", fontSize:"16px", backgroundColor:"#173C92", '&:hover': {backgroundColor:"#173C92"}}}
                          >View Details</Button>      
                            <Typography sx={{mb:3,fontFamily:"Inter", textAlign:"center", fontSize:"16px", lineHeight:"24px", letterSpacing:"-0.5px", fontWeight:"500", color:"#0F2C6F", textTransform:"Capitalize"}}
                          >Return Home</Typography>  
                          <Box sx={{display:"flex", justifyContent:"center", mb:2}}>
                              <Typography sx={{ width:"134px", height:"5px", borderRadius:"100px", backgroundColor:"#02194D"}} />
                          </Box>    
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>}
          </Box>
        </Grid>
      </>
    );
  };
  return (
    <>
      {alertStatus && (
        <Alert
          confirmFunc={saveAsDraft}
          closeFunc={() => {
            setAlertStatus();
            if (uploadedImg != null && !isCroppedSubmit) return;
            navigate(-1);
          }}
          alertData={{
            label: "Are you sure want to save as draft",
            description: "",
            buttonvalue: "Save as Draft",
          }}
        />
      )}
      {loaderStatus && <Loader status={loaderStatus} />}
      {toastData.status && (
        <ToastAlert
          message={toastData.message}
          status={toastData.status}
          color={toastData.color}
        />
      )}
      <Grid
        container
        rowSpacing={2}
        sx={{ padding: 5, pt: 8, background: "rgba(143, 146, 161,0.05)" }}
      >
        <Grid item xs={12}>
          <ArrowIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleBackButton()}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: "26px", fontWeight: "500", color: "#002D74" }}
          >
            {id == "null" ? "Create Key Highlight" : "Edit Key Highlight"}
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: "500", color: "#002D74" }}
          >
            You may create a key highlight which will be shown to all or
            selected app & dashboard users.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              background: "white",
              borderBottom: "1px solid black",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { background: "#123FA9" } }}
            >
              <StyledTab
                label="Create"
                {...a11yProps(0)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
              <StyledTab
                label="Preview"
                {...a11yProps(1)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {renderNewEvent(eventData)}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {renderNewPreview()}
          </TabPanel>
        </Grid>
        <Grid item xs={4} sx={{ px: 2 }}>
          <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "5px" }}>
            <Grid item xs={12} sx={{ mb: 3, p: 0.5 }}>
              <Typography
                sx={{
                  display: "inline",
                  color: "#0F2C6F",
                  fontSize: "14px",
                  m: 2,
                }}
              >
                Post Setting
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Grid>
            <Chip
              label="Post Now"
              onClick={() => setPostType("now")}
              sx={postType === "now" ? uncheckedStyle : checkedStyle}
            />
            <Chip
              label="Schedule Post"
              onClick={() => setPostType("scheduled")}
              sx={postType === "scheduled" ? uncheckedStyle : checkedStyle}
            />
          </Box>
          {postType === "now" ? (
            <>
              <Box sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
                <Grid container>
                  <Grid item xs={7} sx={{ p: 1 }}>
                    <StyledTypography sx={{ mb: 1 }}>
                      End Date*
                    </StyledTypography>
                    <DesktopDatePicker
                      disablePast
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      inputFormat="dd/MM/yyyy"
                      value={eventData?.promotionEndDate}
                      onChange={(e) => handleDate(e, "promotionEndDate")}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                    />
                    <Typography
                      variant="p"
                      sx={{ fontSize: "14px", color: "red" }}
                    >
                      {error?.promotionEndDate}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ p: 1 }}>
                    {getplatformScren()}
                  </Grid>
                  <Grid item xs={12} sx={{ p: 1 }}>
                    {getPopMessage()}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Box sx={{ backgroundColor: "#FFFFFF" }}>
              <Grid container>
                {/* <Grid container item xs={12} > */}
                <Grid item xs={6} sx={{ p: 2 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    Start Date*
                  </StyledTypography>
                  <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    disablePast
                    components={{
                      OpenPickerIcon: DatePickerIcon,
                    }}
                    disabled={currentTab == 0 && edit == "true"}
                    value={eventData?.promotionStartDate}
                    // maxDate={eventData?.eventEndDate && new Date(eventData?.eventEndDate)}
                    onChange={(e) => handleDate(e, "promotionStartDate")}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => preventDatePickerKeyboard(e)}
                        {...params}
                      />
                    )}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionStartDate}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ p: 2 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    End Date*
                  </StyledTypography>
                  <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    disablePast
                    components={{
                      OpenPickerIcon: DatePickerIcon,
                    }}
                    // maxDate={eventData?.eventEndDate && new Date(eventData?.eventEndDate)}
                    value={eventData?.promotionEndDate}
                    onChange={(e) => handleDate(e, "promotionEndDate")}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => preventDatePickerKeyboard(e)}
                        {...params}
                      />
                    )}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionEndDate}
                  </Typography>
                </Grid>

                {/* </Grid> */}

                {/* <Grid container item xs={12} sx={{ my: 2 }}> */}
                <Grid item xs={6} sx={{ p: 2 }}>
                  <StyledTypography>Start Time*</StyledTypography>
                  <Select
                    MenuProps={MenuProps}
                    disabled={
                      (currentTab == 0 && edit == "true") ||
                      eventData.promotionStartDate == null ||
                      eventData.promotionStartDate == ""
                    }
                    fullWidth
                    value={
                      eventData?.promotionStartTime
                        ? eventData?.promotionStartTime
                        : ""
                    }
                    onChange={(e) => {
                      handleTime(e, "promotionStartTime");
                      getEndHours(e.target.value);
                    }}
                  >
                    {scheduleStartHours?.map((value) => (
                      <MenuItem
                        sx={{
                          color: "#0F2C6F",
                          display:
                            eventData?.promotionStartTime == value
                              ? "none"
                              : "",
                        }}
                        value={value}
                      >
                        {value?.slice(0, 6)}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <TextField fullWidth  onChange={(e) => handleTime(e, "promotionStartTime")} type="time" value={eventData?.promotionStartTime}></TextField> */}
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionStartTime}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ p: 2 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    End Time*{" "}
                  </StyledTypography>
                  <Select
                    MenuProps={MenuProps}
                    fullWidth
                    disabled={
                      eventData.promotionEndDate == null ||
                      eventData.promotionEndDate == ""
                    }
                    value={
                      eventData?.promotionEndTime
                        ? eventData.promotionEndTime
                        : ""
                    }
                    onChange={(e) => handleTime(e, "promotionEndTime")}
                  >
                    {scheduleEndHours?.map((value) => (
                      <MenuItem
                        sx={{
                          color: "#0F2C6F",
                          display:
                            eventData?.promotionEndTime == value ? "none" : "",
                        }}
                        value={value}
                      >
                        {value?.slice(0, 6)}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <TextField fullWidth  onChange={(e) => handleTime(e, "promotionEndTime")} type="time" value={eventData?.promotionEndTime}></TextField> */}
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionEndTime}
                  </Typography>
                </Grid>

                {/* </Grid> */}

                <Grid item xs={12} sx={{ p: 2 }}>
                  {getplatformScren()}
                </Grid>
                <Grid item xs={12} sx={{ p: 1 }}>
                    {getPopMessage()}
                  </Grid>
              </Grid>
            </Box>
          )}
          <Button
            onClick={() => navigate(-1)}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#FFFFFF",
              "&:hover": { backgroundColor: "#FFFFFF" },
              height: "50px",
              border: " 1px solid #AAC3FF",
              borderRadius: "8px",
              mt: 2,
            }}
          >
            Back
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              textTransform: "capitalize",
              height: "50px",
              backgroundColor: "#123FA9",
              borderRadius: "8px",
              mt: 2,
            }}
            onClick={clickContinue}
          >
            {tabValue == 0 ? "Continue" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    promotionProperties: state.eAndpReducer.promotionProperties,
    promotionCreateStatus: state.eAndpReducer.promotionCreateStatus,
    eventAndPromotionDetails: state.eAndpReducer.eventAndPromotionDetails,
    updateDraftStatus: state.eAndpReducer.updateDraftStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPromotion: (reqData) => {
      dispatch(eventsAndPromotionAction.createPromotion(reqData));
    },
    getPromotionProperties: (reqData) => {
      dispatch(eventsAndPromotionAction.getPromotionProperties(reqData));
    },
    eventPromotionStateNull: () => {
      dispatch(eventsAndPromotionAction.eventPromotionStateNull());
    },
    getEandPDetails: (data) =>
      dispatch(eventsAndPromotionAction.getEandPDetails(data)),
    updateEandPDraft: (data) =>
      dispatch(eventsAndPromotionAction.updateEandPDraft(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePromotion);
