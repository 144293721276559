import React from "react";
import Chart from "react-apexcharts";

const ServiceMonthGraph = () => {
  const options = {
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    chart: {
      type: "bar", // Set to "bar" for a bar chart
      height: 350,
      toolbar: {
        show: false, // Disable toolbar
      },
    },
    dataLabels: {
      enabled: true,
      background: {
        enabled: false, // Disable background box
      },
      offsetY: -5,
    },
    colors: [
      "#FF5733", // Jan
      "#33FF57", // Feb
      "#5733FF", // Mar
      "#FF33A1", // Apr
      "#33A1FF", // May
      "#A1FF33", // Jun
      "#FF8533", // Jul
      "#FF33D4", // Aug
      "#33FFD4", // Sep
      "#D433FF", // Oct
      "#FF9133", // Nov
      "#33FF91", // Dec
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      show: false, // Disable legend
    },
  };

  const series = [
    {
      name: "Faults",
      data: [10, 20, 15, 25, 30, 28, 18, 22, 17, 29, 35, 40], // Example fault data
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ServiceMonthGraph;
