import React from "react";
import Chart from "react-apexcharts";

const LightBulbGraph = () => {
  const options = {
    plotOptions: {
      bar: {
        distributed: false,
      },
    },
    chart: {
      type: "bar", // Set to "bar" for a bar chart
      height: 350,
      toolbar: {
        show: false, // Disable toolbar
      },
    },
    dataLabels: {
      enabled: true,
      background: {
        enabled: false, // Disable background box
      },
      offsetY: -5,
    },
    colors: [
      "#5733FF", // Mar
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      show: false, // Disable legend
    },
  };

  const series = [
    {
      name: "Light Bulb Service Requests",
      data: [10, 20, 15, 25, 30, 28, 18, 22, 17, 29, 35, 40], // Example fault data
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default LightBulbGraph;
