import React, { useState } from "react";
import BmsCoordinator from "./BmsCoordinator";
import BmsManager from "./BmsManager";
import TenantManager from "./TenantManager";
import SuperAdmin from "./SuperAdmin";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
import NotificationPopup from "../Pages/common/Notification";
import { connect } from "react-redux";
import * as commonAction from '../redux/actions/commonAction'
import Receptionist from "./Receptionist";
import BmsExecutive from "./BmsExecutive";
import { getMessaging,isSupported } from "firebase/messaging";
import 'firebase/messaging' 
import FCC from "./FCC";
import TECTeam from "./TecTeam";
import CustomSnackBar from "../components/common/CustomSnackBar";
import AuditTeam from "./AuditTeam";
import { initApi } from "../components/LandingPage";

const Routing = ({saveFcmToken}) => {
  const [userType, setUserType] = React.useState("");
  async function requestPermission() {
    // if('serviceWorker' in navigator){
    //   navigator.serviceWorker.register('../../public/firebase-messaging-sw.js')
    // }
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BOOoldjqer9VryAWZb7KB3Lpes3ftgITcZBKg_yPrjlxYN7tRuPVuZIOlmHbadUJ5thaN8S5FCGSEMcqNQgFsqI"
      });
      if(sessionStorage.getItem('currentToken')===null||token!==sessionStorage.getItem('currentToken')){
        initApi(() => saveFcmToken({fcm_token:token,login_type:'website',status:permission})) 
        sessionStorage.setItem('currentToken',token)
      }
    } 
    else if (permission === "denied") {
      initApi(() => saveFcmToken({fcm_token:null,login_type:'website',status:permission}))
      alert("You denied for the notification");
    }
  }

  React.useEffect(() => {
    let user = localStorage.getItem("userData");
    let userData = JSON.parse(user);

    if (user) {
      setUserType(userData.role);
      requestPermission();
    }
  }, []);
console.log('userType ',userType)
  return (
    <React.Fragment>
      <CustomSnackBar />
      <NotificationPopup>
        {userType === "BMS Manager" && <BmsManager />}
        {userType === "BMS Executive" && <BmsExecutive /> }
        {userType === "BMS Coordinator" && <BmsCoordinator />}
        {userType === "Tenant Manager" && <TenantManager />}
        {userType === "receptionist" && <Receptionist />}
        {userType === "super_admin" && <SuperAdmin />}
        {userType === "FCC" && <FCC />}        
        {userType === "TEC Team" && <TECTeam />}     
        {userType === "Audit Team" && <AuditTeam /> }   
      </NotificationPopup>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveFcmToken: (data, token) =>
      dispatch(commonAction.saveFCMToken(data, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Routing);
