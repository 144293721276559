import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import Chart from "react-apexcharts";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as NotifyIcon } from "../../Assets/icons/dashboardnotification.svg";
import {
  getLoggedUserData,
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
} from "../../Utils/utils";
import * as DashboardAction from "../../redux/actions/Dashboard Action/index";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GroupIcon } from "../../Assets/icons/MemberIcon.svg";
import { ReactComponent as RefreshIcon } from "../../Assets/icons/ColoredRefreshIcon.svg";
import { ReactComponent as DownloadIcon } from "../../Assets/icons/Download.svg";
import DashboardChart from "./DashboardChart";
import moment from "moment";
import { dummyChartData } from "./chartCustomData";
import ApiService from "../../AxiosConfig/ApiService";
import { ReactComponent as LogoIcon } from "../../Assets/icons/Logo.svg";
import html2PDF from "jspdf-html2canvas";
import OverviewFaultGraph from "./OverviewFalutGraph";
import OverviewSevice from "./OverviewService";
import FaultMonthGraph from "./FalutMonthGraph";
import FaultLocationGraph from "./FaultLocationGraph";
import PercentWiseGraph from "./PercentWiseGraph";
import MediaTimeGraph from "./MediaTimeGraph";
import PriorityLevelGraph from "./PriorityLevelGraph";
import ServiceMonthGraph from "./ServiceMonthGraph";
import ServiceMediaGraph from "./ServiceMediaGraph";
import ServicePercentWiseGraph from "./ServicePercentWiseGraph";
import AirconGraph from "./AirconGraph";
import LightBulbGraph from "./LightBulbGraph";
import { sessionExpired } from '../../redux/actions/commonAction';
import { initApi } from "../../components/LandingPage";

const StyledPrimaryTypography = styled(Typography)({
  color: " #0F2C6F",
  fontSize: "16px",
  fontWeight: 550,
});

const StyledSecTypography = styled(Typography)({
  color: "#0F2C6F",
  fontSize: "14px",
  fontWeight: 500,
});

const StyledTableHeadCell = styled(TableCell)({
  fontSize: "14px",
  color: "#0F2C6F",
  fontWeight: 500,
  borderBottom: "none",
  borderRight: "2px solid rgb(229, 231, 235)",
  width: "20%"
});

const StyledTableBodyCell = styled(TableCell)({
  fontSize: "20px",
  color: "#02194D",
  fontWeight: 600,
  borderBottom: "none",
  borderRight: "2px solid rgb(229, 231, 235)",
  width: "20%"
});

const StyledButton = styled(Button)({
  color: "#0F2C6F",
  height: "48px",
  fontSize: "14px",
  fontWeight: 550,
  border: "1px solid #2764F4",
  borderRadius: "6px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
});

const checkedStyle = {
  backgroundColor: "#FFFFFF",
  color: "#6F80A9",
  border: "1px solid #6F80A9",
  mr: 2,
  "&:hover": { backgroundColor: "#FFFFFF" },
};
const uncheckedStyle = {
  backgroundColor: "#123FA9",
  color: "#FFFFFF",
  mr: 2,
  "&:hover": { backgroundColor: "#123FA9" },
};
let SelectDays = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
  "Custom",
];
// let SelectDays = ["Today","Yesterday","Last 7 days","Last 30 days","Last 90 days","Last 1 year","Custom"]

const BmsManagerDashboard = ({
  getDbData,
  dbData,
  getRealtimeTurnStile,
  getRealtimeTurnStileData,
}) => {
  let navigate = useNavigate();
  const [rangeDate, setRangeDate] = useState(null);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState("Last 7 days");
  const [tenantStatus, setTenantStatus] = useState({
    company: true,
    employee: false,
  });
  const [faultData, setFaultData] = useState({
    ongoing: null,
    completed: null,
    withdraw: null,
    total: null,
  });
  const [serviceData, setServiceData] = useState({
    ongoing: null,
    completed: null,
    withdraw: null,
    total: null,
  });
  const [accessData, setAccessData] = useState({
    tenant: null,
    visitor: null,
    invites: null,
    total: null,
  });
  const [staffData, setStaffData] = useState({
    active: null,
    onboarding: null,
    offboarding: null,
    total: null,
  });
  const [tenantData, setTenantData] = useState({
    activeEmp: null,
    onboardingEmp: null,
    offboardingEmp: null,
    totalEmp: null,
    activeTenant: null,
    onboardingTenant: null,
    offboardingTenant: null,
    totalTenant: null,
  });
  const [customDate, setCustomDate] = useState({
    start: null,
    end: null,
  });
  const data = {
    totalCases: 1595,
    completed: "98%",
    totalOngoing: 45,
    rejected: 10,
    withdrawn: 50,
    dateRange: "1 Jan 2024 to 4 Nov 2024",
  };
  const [turnStileData, setTurnStileData] = useState({});

  const [TurnStileUpdatedTime, setTurnStileUpdatedTime] = useState();

  const [RoleTypeInData, setRoleTypeInData] = useState([
    { id: 1, name: "Tenant", value: dbData?.tenant_in },
    { id: 2, name: "Visitor", value: dbData?.visitor_in },
    { id: 3, name: "Staff", value: dbData?.staff_in },
  ]);
  const [RoleTypeOutData, setRoleTypeOutData] = useState([
    { id: 1, name: "Tenant", value: dbData?.tenant_out },
    { id: 2, name: "Visitor", value: dbData?.visitor_out },
    { id: 3, name: "Staff", value: dbData?.staff_out },
  ]);

  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const [dateData, setDateData] = useState({ startDate: "", endDate: "" });

  const [userData, setUserData] = useState(null);

  const tableHeadArr = [
    "Total Cases",
    "Completed",
    "Total Ongoing",
    "Rejected",
    "Withdrawn",
  ];

  useEffect(() => {

    initApi(getRealtimeTurnStile)

    const userDetails = localStorage.getItem("userData");
    if (userDetails) {
      try {
        const parsedDetails = JSON.parse(userDetails); // Parse JSON
        setUserData(parsedDetails); // Set state with parsed details
      } catch (error) {
        console.error("Error parsing user details:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (getRealtimeTurnStileData.response?.response_status === 1) {
      setTurnStileData(getRealtimeTurnStileData.response?.response_data);
      setTurnStileUpdatedTime(moment(Date.now()).format("DD MMM YYYY, HH:mm"));
    }
  }, [getRealtimeTurnStileData]);

  console.log(TurnStileUpdatedTime, turnStileData);

  useEffect(() => {
    let today = new Date();
    let date = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    console.log("yes ", yesterday);

    if (filterDate == "Yesterday") {
      date.setDate(today.getDate() - 1);
    } else if (filterDate == "Last 7 days") {
      date.setDate(today.getDate() - 7);
    } else if (filterDate == "Last 30 days") {
      date.setDate(today.getDate() - 31);
    }
    // else if(filterDate=="Last 90 days"){
    //     date.setDate(today.getDate()-90)
    // }else if(filterDate=="Last 1 year"){
    //     date.setDate(today.getDate()-365)
    // }

    if (filterDate != "Custom") {
      let data = {
        start_date: getYYYYMMDDFormat(date),
        // start_date: '2023-12-21' ,
        end_date:
          filterDate == "Today"
            ? getYYYYMMDDFormat(new Date())
            : getYYYYMMDDFormat(yesterday),
        // end_date: '2023-12-21',
        limit: 10,
        offset: 0,
        currentMonth: "",
      };
      setDateData({ startDate: data.start_date, endDate: data.end_date });
      // getDbData(data);
      initApi(() => getDbData(data))
    } else if (customDate.start != null && customDate.end != null) {
      let data = {
        start_date: getYYYYMMDDFormat(customDate.start),
        end_date: getYYYYMMDDFormat(customDate.end),
        limit: 10,
        offset: 0,
        currentMonth: "",
      };
      setDateData({ startDate: data.start_date, endDate: data.end_date });
      // getDbData(data);
      initApi(() => getDbData(data))
    }
  }, [filterDate, customDate]);

  useEffect(() => {
    getDateRange();
  }, [filterDate]);

  useEffect(() => {
    if (dbData) {
      setFaultData({
        ongoing: dbData.ongoing_fault_report,
        completed: dbData.completed_fault_report,
        withdraw: dbData.withdraw_fault_report,
        total: dbData.total_fault_report,
      });

      setServiceData({
        ongoing: dbData.ongoing_service_request,
        completed: dbData.completed_service_request,
        withdraw: dbData.withdraw_service_request,
        total: dbData.total_service_requests,
      });

      setAccessData({
        tenant: dbData.tenant,
        visitor: dbData.visitors,
        invites: dbData.invite,
        total: dbData.total_accesses,
      });

      setStaffData({
        active: dbData.active_bms_staff,
        onboarding: dbData.onboarding_bms_staff,
        offboarding: dbData.offboarding_bms_staff,
        total: dbData.total_lots,
      });

      setTenantData({
        activeEmp: dbData.active_employees,
        onboardingEmp: dbData.onboarding_employees,
        offboardingEmp: dbData.offboarding_employees,
        totalEmp: dbData.total_employees,
        activeTenant: dbData.active_companies,
        onboardingTenant: dbData.onboarding_companies,
        offboardingTenant: dbData.offboarding_companies,
        totalTenant: dbData.total_companies,
      });

      setRoleTypeInData([
        { id: 1, name: "Tenant", value: dbData?.tenant_in },
        { id: 2, name: "Visitor", value: dbData?.visitor_in },
        { id: 3, name: "Staff", value: dbData?.staff_in },
      ]);

      setRoleTypeOutData([
        { id: 1, name: "Tenant", value: dbData?.tenant_out },
        { id: 2, name: "Visitor", value: dbData?.visitor_out },
        { id: 3, name: "Staff", value: dbData?.staff_out },
      ]);
    }
  }, [dbData]);

  const getDateRange = () => {
    const today = new Date();
    const previousDate = new Date();

    if (filterDate == "Today") {
      setRangeDate(today);
      setRangeEndDate(today);
    } else if (filterDate == "Yesterday") {
      previousDate.setDate(previousDate.getDate() - 1);
      setRangeDate(previousDate);
      setRangeEndDate(previousDate);
    } else if (filterDate == "Last 7 days") {
      previousDate.setDate(previousDate.getDate() - 7);
      setRangeDate(previousDate);
      setRangeEndDate(today.setDate(today.getDate() - 1));
    } else if (filterDate == "Last 30 days") {
      previousDate.setDate(previousDate.getDate() - 31);
      setRangeDate(previousDate);
      setRangeEndDate(today.setDate(today.getDate() - 1));
    }
    // else if(filterDate=="Last 90 days"){
    //     previousDate.setDate(previousDate.getDate()-90)
    //     setRangeDate(previousDate)
    // }else if(filterDate=="Last 1 year"){
    //     previousDate.setDate(previousDate.getDate()-365)
    //     setRangeDate(previousDate)
    // }
  };

  const downloadDashboardExcelData = async () => {
    try {
      if (dateData.startDate && dateData.endDate) {
        setDownloadIsLoading(true);
        let response = await ApiService().get(
          `/turnstile_full_rpt?start_date_time=${dateData.startDate}&end_date_time=${dateData.endDate}`,
          { responseType: "arraybuffer" }
        );
        if (response) {
          setDownloadIsLoading(false);
        }
        if (response.status === 200) {
          // saveAs(response.data);
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          let tag = document.createElement("a");
          tag.setAttribute("href", url);
          tag.setAttribute("download", `TurnstileAccessReports.xlsx`);
          tag.click();
        }
      }
    } catch (error) {
      console.log(error.response.data);
      setDownloadIsLoading(false);
    }
  };

  const handlePdfDownload = () => {
    let data = document.getElementById("print");
    html2PDF(data, {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/jpeg",
      output: `Dashboard.pdf`,
    });
  };

  const renderBarChart = (horizontal, data1, data2, data3) => {
    let DASHBOARD_CHART_DATA = {
      series: [
        {
          name: [""],
          data: [data1, data2, data3],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
            distributed: true,
            borderRadius: 0,
            dataLabels: {
              position: "", // top, center, bottom
            },
            borderRadiusApplication: "end",
            borderRadius: 8,
          },
        },
        colors: ["#0F2C6F", "#2764F4", "#AAC3FF"],
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: ["", "", ""],
          position: "bottom",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#B7C0D4",
                colorTo: "#B7C0D4",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          floating: true,
          offsetY: 0,
          align: "left",
          style: {
            color: "#444",
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
      },
    };
    return (
      <>
        <Chart
          style={{
            boxShadow: "none",
            margin: "-20px 0 0 -10px",
          }}
          options={DASHBOARD_CHART_DATA.options}
          series={DASHBOARD_CHART_DATA.series}
          type="bar"
          height="220px"
        />
      </>
    );
  };

  const renderRoundChart = (data1, data2, data3, label1, label2, label3) => {
    var chart = {
      series: [data1, data2, data3],
      options: {
        chart: {
          width: 380,
          type: "donut",
          dropShadow: {
            enabled: true,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2,
          },
        },
        labels: [label1, label2, label3],
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: false,
                  show: true,
                },
              },
            },
          },
        },
        colors: ["#0F2C6F", "#2764F4", "#AAC3FF"],
        dataLabels: {
          enabled: false,
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        states: {
          hover: {
            filter: "none",
          },
        },
        legend: {
          show: false,
        },
      },
    };
    return (
      <>
        <Chart
          type="donut"
          height="200px"
          series={chart.series}
          options={chart.options}
        ></Chart>
      </>
    );
  };

  const renderCountList = (
    totalName,
    totalCount,
    listoneName,
    listoneCount,
    listtwoName,
    listtwoCount,
    listthreeName,
    listthreeCount
  ) => {
    return (
      <Box sx={{ padding: 2 }}>
        <List>
          <ListItem
            secondaryAction={
              <StyledPrimaryTypography>{totalCount}</StyledPrimaryTypography>
            }
          >
            <ListItemText>
              <StyledPrimaryTypography>{totalName}</StyledPrimaryTypography>
            </ListItemText>
          </ListItem>
        </List>
        <Divider sx={{ border: "1px solid #DBDFE9" }} />
        <List>
          <ListItem
            secondaryAction={
              <StyledSecTypography>{listoneCount}</StyledSecTypography>
            }
          >
            <ListItemIcon>
              <Box
                sx={{
                  height: "8px",
                  width: "8px",
                  backgroundColor: "#0F2C6F",
                  borderRadius: "45%",
                }}
              ></Box>
            </ListItemIcon>
            <ListItemText>
              <StyledSecTypography>{listoneName}</StyledSecTypography>
            </ListItemText>
          </ListItem>
          <Divider sx={{ border: "1px solid #DBDFE9" }} />
          <ListItem
            secondaryAction={
              <StyledSecTypography>{listtwoCount}</StyledSecTypography>
            }
          >
            <ListItemIcon>
              <Box
                sx={{
                  height: "8px",
                  width: "8px",
                  backgroundColor: "#2764F4",
                  borderRadius: "45%",
                }}
              ></Box>
            </ListItemIcon>
            <ListItemText>
              <StyledSecTypography>{listtwoName}</StyledSecTypography>
            </ListItemText>
          </ListItem>
          <Divider sx={{ border: "1px solid #DBDFE9" }} />
          <ListItem
            secondaryAction={
              <StyledSecTypography>{listthreeCount}</StyledSecTypography>
            }
          >
            <ListItemIcon>
              <Box
                sx={{
                  height: "8px",
                  width: "8px",
                  backgroundColor: "#AAC3FF",
                  borderRadius: "45%",
                }}
              ></Box>
            </ListItemIcon>
            <ListItemText>
              <StyledSecTypography>{listthreeName}</StyledSecTypography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    );
  };

  return (
    <>
      <div id="print">
        <Box sx={{ py: 4, px: 2, backgroundColor: "#FFF" }}>
          {/* <Box sx={{display: 'flex', justifyContent:'space-between', width: "100%", background: "#EAF0FF", py: "12px", my: 2, borderRadius: "8px"}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: 2, pl: 3}}>
                    <GroupIcon style={{width: "24px", height: "24px"}} />
                    <Typography sx={{fontSize: "16px", fontWeight: 500}}>There is a service request that requires your attention.</Typography>
                    </Box>
                    <Button sx={{mr: 3, background: '#123FA9', textTransform: 'none', borderRadius: "8px"}} variant="contained">Manage</Button>
                </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              mt: 3,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography
                sx={{ color: "#002D74", fontSize: "26px", fontWeight: 500 }}
              >
                Daily Insights
              </Typography>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>
                  Data accurate as of {TurnStileUpdatedTime}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => getRealtimeTurnStile()}
                >
                  <RefreshIcon
                    className={
                      getRealtimeTurnStileData?.isLoading && "rotate_animation"
                    }
                  />
                  <Typography
                    sx={{
                      color: "#0F2C6F",
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                  >
                    Refresh
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                sx={{
                  background: "#123FA9",
                  textTransform: "none",
                  height: "45px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItem: "center",
                  gap: "6px",
                }}
                variant="contained"
                disabled={downloadIsLoading}
                onClick={() => downloadDashboardExcelData()}
                data-html2canvas-ignore="true"
              >
                Download as Excel <DownloadIcon />{" "}
                {downloadIsLoading && (
                  <CircularProgress size={20} sx={{ color: "#123FA9" }} />
                )}
              </Button>
              <Button
                sx={{
                  background: "#123FA9",
                  textTransform: "none",
                  height: "45px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItem: "center",
                  gap: "6px",
                }}
                variant="contained"
                onClick={() => handlePdfDownload()}
                data-html2canvas-ignore="true"
              >
                Download as PDF <DownloadIcon />{" "}
                {downloadIsLoading && (
                  <CircularProgress size={20} sx={{ color: "#123FA9" }} />
                )}
              </Button>
            </Box>
          </Box>

          <Grid
            container
            sx={{
              width: "100%",
              my: 2,
              background: "white",
              borderRadius: "4px",
              boxShadow: "1px 1px #55555510",
            }}
          >
            <Grid
              item
              xs={6}
              sm={4}
              sx={{ borderRight: "1px solid #55555520", p: 2 }}
            >
              <Typography
                sx={{ fontSize: "14px", color: "#333", fontWeight: 500 }}
              >
                In Building
              </Typography>
              <Typography
                sx={{ fontSize: "20px", color: "#02194D", fontWeight: 600 }}
              >
                {turnStileData?.in_building}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              sx={{ borderRight: "1px solid #55555520", p: 2 }}
            >
              <Typography
                sx={{ fontSize: "14px", color: "#333", fontWeight: 500 }}
              >
                Scan In
              </Typography>
              <Typography
                sx={{ fontSize: "20px", color: "#02194D", fontWeight: 600 }}
              >
                {turnStileData?.total_in}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                p: 2,
                borderTop: { xs: "1px solid #55555520", sm: "none" },
              }}
            >
              <Typography
                sx={{ fontSize: "14px", color: "#333", fontWeight: 500 }}
              >
                Scan Out
              </Typography>
              <Typography
                sx={{ fontSize: "20px", color: "#02194D", fontWeight: 600 }}
              >
                {turnStileData?.total_out}
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 4,
              alignItems: "center",
            }}
          >
            {/* <Typography sx={{color:"#002D74",fontSize:"32px",textTransform:"capitalize"}}>Welcome {getLoggedUserData()?.user_name}</Typography> */}
            <Typography
              sx={{
                color: "#002D74",
                fontSize: "26px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Dashboard Overview
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Select
                defaultValue={"Last 30 days"}
                value={filterDate}
                sx={{ width: 150, mr: 3, color: "#0F2C6F" }}
                onChange={(e) => setFilterDate(e.target.value)}
              >
                {SelectDays.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
              {filterDate == "Custom" ? (
                <>
                  <Box sx={{ width: 150 }}>
                    <DatePicker
                      inputFormat="dd MMM yyyy"
                      disableFuture
                      value={customDate.start}
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      onChange={(e) =>
                        setCustomDate({
                          ...customDate,
                          start: e,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          sx={{ background: "white" }}
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                      sx={{ background: "white" }}
                    />
                  </Box>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", mx: 0.5 }}
                  >
                    {" "}
                    -{" "}
                  </Typography>
                  <Box sx={{ width: 150 }}>
                    <DatePicker
                      minDate={customDate.start}
                      onChange={(e) =>
                        setCustomDate({
                          ...customDate,
                          end: e,
                        })
                      }
                      disableFuture
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      inputFormat="dd MMM yyyy"
                      value={customDate.end}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ width: 120 }}>
                    <DatePicker
                      inputFormat="dd MMM yyyy"
                      disableOpenPicker
                      disabled
                      value={rangeDate}
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                    />
                  </Box>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", mx: 0.5 }}
                  >
                    {" "}
                    -{" "}
                  </Typography>
                  <Box sx={{ width: 120 }}>
                    <DatePicker
                      inputFormat="dd MMM yyyy"
                      value={rangeEndDate}
                      disableOpenPicker
                      disabled
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>

          {/* <Box sx={{border:"1px solid #2764F4",borderRadius:"6px",display:"flex",justifyContent:"space-between",my:2,px:3,py:1}}>
                    <Typography sx={{color:"#2764F4",fontSize:"14px"}}> <NotifyIcon style={{marginTop:"8px",marginRight:'18px'}} /> 
                        There will be fire drill on 30 October 2022, please be prepare to be at the meeting point of the building.
                    </Typography>
                    <Button variant="text" sx={{textTransform:"capitalize",textDecoration:'underline'}}>View All</Button>
                </Box> */}

          <Grid container sx={{ my: 2 }}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                background: "white",
                py: 2,
                px: 3,
                borderRadius: "6px",
                border: "0.5px solid #F3F4F8",
              }}
            >
              <Box>
                <Typography sx={{ color: "#333333", fontSize: "14px" }}>
                  Total Scan In
                </Typography>
                <Typography
                  sx={{ color: "#02194D", fontSize: "20px", fontWeight: 600 }}
                >
                  {dbData?.total_scan_in}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#F5F8FF",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 1,
                  borderRadius: "6px",
                }}
              >
                {RoleTypeInData.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#6F80A9",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#6F80A9",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                background: "white",
                py: 2,
                px: 3,
                borderRadius: "6px",
                border: "0.5px solid #F3F4F8",
              }}
            >
              <Box>
                <Typography sx={{ color: "#333333", fontSize: "14px" }}>
                  Total Scan Out
                </Typography>
                <Typography
                  sx={{ color: "#02194D", fontSize: "20px", fontWeight: 600 }}
                >
                  {dbData?.total_scan_out}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#F5F8FF",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 1,
                  borderRadius: "6px",
                }}
              >
                {RoleTypeOutData.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#6F80A9",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#6F80A9",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%" }}>
            {dbData?.chart_data && (
              <DashboardChart chartData={dbData?.chart_data} />
            )}
            {/* <DashboardChart chartData={dummyChartData}/> */}
          </Box>

          <Grid container>
            <Grid
              item
              xs={5.8}
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#F5F8FF",
                  p: 2,
                }}
              >
                <Box>
                  <StyledPrimaryTypography>
                    Fault Report
                  </StyledPrimaryTypography>
                  <StyledSecTypography>
                    Total: {faultData.total}
                  </StyledSecTypography>
                </Box>
                <StyledButton
                  variant="outlined"
                  onClick={() => navigate(`/manage_faults/0`)}
                  sx={{ background: "white", border: "1px solid #AAC3FF" }}
                >
                  Manage
                </StyledButton>
              </Box>
              {/* <Typography>New report: </Typography> */}
              {renderBarChart(
                false,
                faultData.ongoing,
                faultData.completed,
                faultData.withdraw
              )}
              {renderCountList(
                "Total Report",
                faultData.total,
                "Ongoing",
                faultData.ongoing,
                "Completed",
                faultData.completed,
                "Withdrawn",
                faultData.withdraw
              )}
            </Grid>

            <Grid item xs={0.2} />

            <Grid
              item
              xs={5.8}
              sx={{
                backgroundColor: "#FFFFFF",
                maxHeight: "550px",
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#F5F8FF",
                  p: 2,
                }}
              >
                <Box>
                  <StyledPrimaryTypography>
                    Service Request
                  </StyledPrimaryTypography>
                  <StyledSecTypography>
                    Total: {serviceData.total}
                  </StyledSecTypography>
                </Box>
                <StyledButton
                  variant="outlined"
                  onClick={() => navigate(`/service_request_list/0`)}
                >
                  Manage
                </StyledButton>
              </Box>
              {/* <Typography>New report: 3</Typography> */}
              {renderBarChart(
                true,
                serviceData.ongoing,
                serviceData.completed,
                serviceData.withdraw
              )}
              {renderCountList(
                "Total Requests",
                serviceData.total,
                "Ongoing",
                serviceData.ongoing,
                "Completed",
                serviceData.completed,
                "Withdrawn",
                serviceData.withdraw
              )}
            </Grid>

            <Grid item xs={0.2} />

            <Grid
              item
              xs={5.8}
              sx={{
                backgroundColor: "#FFFFFF",
                mt: 2,
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#F5F8FF",
                  p: 2,
                }}
              >
                <Box>
                  <StyledPrimaryTypography>BMS Staff</StyledPrimaryTypography>
                  <StyledSecTypography>
                    Total: {staffData.total}
                  </StyledSecTypography>
                </Box>
                <StyledButton
                  variant="outlined"
                  onClick={() => navigate(`/manage_staff/0`)}
                >
                  Manage
                </StyledButton>
              </Box>
              {/* <Typography>New report: 3</Typography> */}
              <Box sx={{ mt: 3 }}>
                {renderRoundChart(
                  staffData.active,
                  staffData.onboarding,
                  staffData.offboarding,
                  "Active",
                  "Onboarding",
                  "Offboarding"
                )}
              </Box>
              {renderCountList(
                "Total Lots",
                staffData.total,
                "Active",
                staffData.active,
                "Onboarding",
                staffData.onboarding,
                "Offboarding",
                staffData.offboarding
              )}
            </Grid>
            <Grid item xs={0.2} />

            <Grid
              item
              xs={5.8}
              sx={{
                backgroundColor: "#FFFFFF",
                mt: 2,
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#F5F8FF",
                  p: 2,
                }}
              >
                <StyledPrimaryTypography>Tenant</StyledPrimaryTypography>
                <StyledButton
                  variant="outlined"
                  onClick={() => navigate(`/onboard_new_tenant/0/0`)}
                >
                  Manage
                </StyledButton>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    my: 2,
                    px: 4,
                    gap: 2,
                  }}
                >
                  <Chip
                    onClick={() => {
                      setTenantStatus({
                        company: true,
                        employee: false,
                      });
                    }}
                    sx={tenantStatus.company ? uncheckedStyle : checkedStyle}
                    label="Company"
                  />
                  <Chip
                    onClick={() => {
                      setTenantStatus({
                        company: false,
                        employee: true,
                      });
                    }}
                    sx={tenantStatus.employee ? uncheckedStyle : checkedStyle}
                    label="Employee"
                  />
                </Box>
                {tenantStatus.employee ? (
                  <Box sx={{ pt: 4 }}>
                    {renderRoundChart(
                      tenantData.activeEmp,
                      tenantData.onboardingEmp,
                      tenantData.offboardingEmp,
                      "Active",
                      "Onboarding",
                      "Offboarding"
                    )}
                  </Box>
                ) : tenantStatus.company ? (
                  <Box sx={{ pt: 4 }}>
                    {renderRoundChart(
                      tenantData.activeTenant,
                      tenantData.onboardingTenant,
                      tenantData.offboardingTenant,
                      "Active",
                      "Onboarding",
                      "Offboarding"
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              {tenantStatus.employee ? (
                <>
                  {renderCountList(
                    "Total Employee",
                    tenantData.totalEmp,
                    "Active",
                    tenantData.activeEmp,
                    "Onboarding",
                    tenantData.onboardingEmp,
                    "Offboarding",
                    tenantData.offboardingEmp
                  )}
                </>
              ) : tenantStatus.company ? (
                <>
                  {renderCountList(
                    "Total Company",
                    tenantData.totalTenant,
                    "Active",
                    tenantData.activeTenant,
                    "Onboarding",
                    tenantData.onboardingTenant,
                    "Offboarding",
                    tenantData.offboardingTenant
                  )}
                </>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={0.2} />

            {/* <Grid item xs={5.8} sx={{backgroundColor:"#FFFFFF",p:2,mt:2}}>   */}
            {/* <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                            <Box>
                                                <StyledPrimaryTypography>Building Access</StyledPrimaryTypography>
                                                <StyledSecTypography>Total: {accessData.total}</StyledSecTypography>
                                            </Box>
                                            <StyledButton onClick={()=>navigate('/reports/true')} variant="outlined">Manage</StyledButton>
                                </Box> */}
            {/* <Typography>New report: 3</Typography> */}
            {/* {renderBarChart(true,accessData.tenant,accessData.visitor,accessData.invites)}  
                                  {renderCountList("Total Access",accessData.total,"Tenant",accessData.tenant,"Visitor",accessData.visitor,"Invites",accessData.invites)}
                            </Grid> */}

            {/* <Grid item xs={0.2} /> */}

            {/* <Grid item xs={3.9} sx={{backgroundColor:"#FFFFFF",p:2,mt:2}}>
                                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                                    <Box>
                                                        <Typography>Seasonal Parking</Typography>
                                                        <Typography>Total: 68</Typography>
                                                    </Box>
                                                    <StyledButton variant="outlined">Manage</StyledButton>
                                    </Box>
                                    <Typography>New report: 3</Typography>
                             </Grid> */}

            {/* <Grid item xs={0.2} /> */}
          </Grid>

          <Box sx={{ borderRadius: "8px", boxShadow: 1, my: 2 }}>
            <Box
              sx={{
                background: "#F5F8FF",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 500, color: "#0F2C6F" }}
                >
                  Estimated Service Request Revenue
                </Typography>
                <Typography sx={{ fontSize: "18px", color: "#0F2C6F99" }}>
                  The amount displayed above is an estimated revenue based on
                  uploaded SAP receipts uploaded by BMS.
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: "20px", fontWeight: 700, color: "#0F2C6F" }}
              >
                ${dbData?.estimatted_service_request_revenue}
              </Typography>
            </Box>

            <Grid
              container
              sx={{
                width: "100%",
                my: 2,
                background: "white",
                borderRadius: "4px",
                boxShadow: "1px 1px #55555510",
              }}
            >
              <Grid
                item
                xs={6}
                sm={4}
                sx={{ borderRight: "1px solid #55555520", p: 2 }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#333", fontWeight: 500 }}
                >
                  Air Con Extension
                </Typography>
                <Typography
                  sx={{ fontSize: "20px", color: "#02194D", fontWeight: 600 }}
                >
                  ${dbData?.aircon_extension_request_revenue}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                sx={{ borderRight: "1px solid #55555520", p: 2 }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#333", fontWeight: 500 }}
                >
                  Light Bulb Replacement
                </Typography>
                <Typography
                  sx={{ fontSize: "20px", color: "#02194D", fontWeight: 600 }}
                >
                  ${dbData?.light_bulb_replacement_revenue}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  p: 2,
                  borderTop: { xs: "1px solid #55555520", sm: "none" },
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#333", fontWeight: 500 }}
                >
                  Ad Hoc Request
                </Typography>
                <Typography
                  sx={{ fontSize: "20px", color: "#02194D", fontWeight: 600 }}
                >
                  ${dbData?.adhoc_request_revenue}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {userData?.role_id === 2 && (
            <Box sx={{ mt: 4 }}>
              <Typography
                sx={{
                  color: "#002D74",
                  fontSize: "26px",
                  fontWeight: 500,
                  // textAlign: "center",
                }}
              >
                Overall Report
              </Typography>
              <Grid container spacing={3} sx={{ mt: 1, marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "6px 6px 0px 0px",
                        backgroundColor: "#F5F8FF",
                        p: 2,
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <StyledPrimaryTypography>
                          Fault Report
                        </StyledPrimaryTypography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2, // Adds some space between the two date pickers
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          label="Start Date"
                          inputFormat="dd/MM/yyyy"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                backgroundColor: "#FFFFFF",
                                "& .MuiInputBase-input": {
                                  color: "#0F2C6F",
                                },
                                "& .MuiInputLabel-root": {
                                  color: "#0F2C6F",
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#0F2C6F",
                                },
                              }}
                            />
                          )}
                        />
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => setEndDate(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                backgroundColor: "#FFFFFF",
                                "& .MuiInputBase-input": {
                                  color: "#0F2C6F",
                                },
                                "& .MuiInputLabel-root": {
                                  color: "#0F2C6F",
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#0F2C6F",
                                },
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <TableContainer
                        sx={{
                          mt: 3,
                          border: "2px solid rgb(229, 231, 235)",
                          borderRadius: "4px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow sx={{ width: "100%" }}>
                              {tableHeadArr.map((value, index) => (
                                <StyledTableHeadCell
                                  key={index}
                                  align="center"
                                  style={{
                                    borderRight:
                                      index === tableHeadArr.length - 1 &&
                                      "none",
                                  }}
                                >
                                  {value}
                                </StyledTableHeadCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ width: "100%" }}>
                              <StyledTableBodyCell align="center">
                                {data.totalCases}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell align="center">
                                {data.completed}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell align="center">
                                {data.totalOngoing}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell align="center">
                                {data.rejected}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ borderRight: "none" }}
                                align="center"
                              >
                                {data.withdrawn}
                              </StyledTableBodyCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <OverviewFaultGraph />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "6px 6px 0px 0px",
                        backgroundColor: "#F5F8FF",
                        p: 2,
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <StyledPrimaryTypography>
                          Service Request
                        </StyledPrimaryTypography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2, // Adds some space between the two date pickers
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          label="Start Date"
                          inputFormat="dd/MM/yyyy"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                backgroundColor: "#FFFFFF",
                                "& .MuiInputBase-input": {
                                  color: "#0F2C6F", // Text color
                                },
                                "& .MuiInputLabel-root": {
                                  color: "#0F2C6F", // Label color
                                },
                                // "& .MuiOutlinedInput-notchedOutline": {
                                //   borderColor: "#2764F4", // Border color if needed
                                // },
                                "& .MuiSvgIcon-root": {
                                  color: "#0F2C6F", // Icon color
                                },
                              }}
                            />
                          )}
                        />
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => setEndDate(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                backgroundColor: "#FFFFFF",
                                "& .MuiInputBase-input": {
                                  color: "#0F2C6F", // Text color
                                },
                                "& .MuiInputLabel-root": {
                                  color: "#0F2C6F", // Label color
                                },
                                // "& .MuiOutlinedInput-notchedOutline": {
                                //   borderColor: "#2764F4", // Border color if needed
                                // },
                                "& .MuiSvgIcon-root": {
                                  color: "#0F2C6F", // Icon color
                                },
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <TableContainer
                        sx={{
                          mt: 3,
                          border: "1.5px solid rgb(229, 231, 235)",
                          borderRadius: "4px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow sx={{ width: "100%" }}>
                              {tableHeadArr.map((value, index) => (
                                <StyledTableHeadCell
                                  key={index}
                                  align="center"
                                  style={{
                                    borderRight:
                                      index === tableHeadArr.length - 1 &&
                                      "none",
                                  }}
                                >
                                  {value}
                                </StyledTableHeadCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ width: "100%" }}>
                              <StyledTableBodyCell align="center">
                                {data.totalCases}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell align="center">
                                {data.completed}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell align="center">
                                {data.totalOngoing}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell align="center">
                                {data.rejected}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ borderRight: "none" }}
                                align="center"
                              >
                                {data.withdrawn}
                              </StyledTableBodyCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <OverviewSevice />
                  </Box>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  color: "#002D74",
                  fontSize: "26px",
                  fontWeight: 500,
                }}
              >
                Manage Faults
              </Typography>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "6px 6px 0px 0px",
                    backgroundColor: "#F5F8FF",
                    padding: "30px 16px 30px 16px",
                  }}
                >
                  <Box>
                    <StyledPrimaryTypography>
                      Fault Report : Jan-2024 to Nov-2024
                    </StyledPrimaryTypography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                  <TableContainer
                    sx={{
                      mt: 3,
                      border: "2px solid rgb(229, 231, 235)",
                      borderRadius: "4px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                          {tableHeadArr.map((value, index) => (
                            <StyledTableHeadCell
                              key={index}
                              align="center"
                              style={{
                                borderRight:
                                  index === tableHeadArr.length - 1 && "none",
                              }}
                            >
                              {value}
                            </StyledTableHeadCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableBodyCell align="center">
                            {data.totalCases}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            {data.completed}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            {data.totalOngoing}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            {data.rejected}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            {data.withdrawn}
                          </StyledTableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <StyledPrimaryTypography
                  sx={{ textAlign: "center", marginTop: "25px" }}
                >
                  Month-on-Month Comparison
                </StyledPrimaryTypography>
                <FaultMonthGraph />
              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={3}
              >

                <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: '6px 6px 0px 0px' }}>
                  <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                    Median Days to Completion Reporting Time
                  </StyledPrimaryTypography>
                </Box>

                <StyledPrimaryTypography sx={{ textAlign: "center", p: 2, }}>
                  3 Days
                </StyledPrimaryTypography>

              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={3}
              >
                <Box
                  sx={{
                    backgroundColor: "#F5F8FF",
                    p: 3,
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                    Median Days to Close Fault Category
                  </StyledPrimaryTypography>
                </Box>

                <Box sx={{ p: 2 }}>
                  <TableContainer
                    sx={{
                      mt: 3,
                      border: "2px solid rgb(229, 231, 235)",
                      borderRadius: "4px",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableHeadCell align="center">
                            Aircon
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Lights
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Cleaning
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Amenity Fault
                          </StyledTableHeadCell>
                          <StyledTableHeadCell
                            style={{
                              borderRight: "none",
                            }}
                            align="center"
                          >
                            Others
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableBodyCell align="center">
                            1 Day
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            3 Days
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4 Days
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            6 Days
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            4 Days
                          </StyledTableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Grid
                container
                spacing={3}
                sx={{ marginTop: "10px", marginBottom: "20px" }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                      backgroundColor: "rgb(255, 255, 255)",
                      marginTop: "20px",
                    }}
                  >
                    <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: "6px 6px 0px 0px" }}>
                      <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                        Count of Reported By Location
                      </StyledPrimaryTypography>
                    </Box>
                    <Box p={2}>
                      <FaultLocationGraph />
                    </Box>

                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                      backgroundColor: "rgb(255, 255, 255)",
                      marginTop: "20px",
                    }}
                  >
                    <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: "6px 6px 0px 0px" }}>
                      <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                        Percent Wise Inc / Dec Based On Categories
                      </StyledPrimaryTypography>
                    </Box>
                    <Box p={2}>
                      <PercentWiseGraph />
                    </Box>

                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                sx={{ marginTop: "10px", marginBottom: "20px" }}
              >
                <Grid item xs={6}>

                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                      backgroundColor: "rgb(255, 255, 255)",
                      marginTop: "20px",
                    }}

                  >
                    <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: "6px 6px 0px 0px" }}>
                      <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                        Median Time for the categories
                      </StyledPrimaryTypography>
                    </Box>
                    <Box p={2}>
                      <MediaTimeGraph />
                    </Box>

                  </Box>
                </Grid>
                <Grid item xs={6}>

                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                      backgroundColor: "rgb(255, 255, 255)",
                      marginTop: "20px",
                    }}

                  >
                    <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: "6px 6px 0px 0px" }}>
                      <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                        No. of Cases Based On Priority Level
                      </StyledPrimaryTypography>
                    </Box>
                    <Box p={2}>
                      <PriorityLevelGraph />
                    </Box>

                  </Box>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  color: "#002D74",
                  fontSize: "26px",
                  fontWeight: 500,
                }}
              >
                Manage Services
              </Typography>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "6px 6px 0px 0px",
                    backgroundColor: "#F5F8FF",
                    padding: "30px 16px 30px 16px",
                  }}
                >
                  <Box>
                    <StyledPrimaryTypography>
                      Service Requests : Jan-2024 to Nov-2024
                    </StyledPrimaryTypography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                  <TableContainer
                    sx={{
                      mt: 3,
                      border: "2px solid rgb(229, 231, 235)",
                      borderRadius: "4px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                          {tableHeadArr.map((value, index) => (
                            <StyledTableHeadCell
                              key={index}
                              align="center"
                              style={{
                                borderRight:
                                  index === tableHeadArr.length - 1 && "none",
                              }}
                            >
                              {value}
                            </StyledTableHeadCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableBodyCell align="center">
                            {data.totalCases}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            {data.completed}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            {data.totalOngoing}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            {data.rejected}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            {data.withdrawn}
                          </StyledTableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <StyledPrimaryTypography
                  sx={{ textAlign: "center", marginTop: "25px" }}
                >
                  Month-on-Month Comparison
                </StyledPrimaryTypography>
                <ServiceMonthGraph />
              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                  backgroundColor: "rgb(255, 255, 255)",
                }}
                mt={3}
              >
                <Box
                  sx={{
                    borderRadius: "6px 6px 0px 0px",
                    backgroundColor: "#F5F8FF",
                    p: 3,
                  }}
                >
                  <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                    Measure Time Taken For Response
                  </StyledPrimaryTypography>
                </Box>

                <Box sx={{ p: 3 }}>
                  <TableContainer
                    sx={{
                      mt: 3,
                      border: "2px solid rgb(229, 231, 235)",
                      borderRadius: "4px",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableHeadCell align="center">
                            Submitted to Approved
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Submitted to Work Completed
                          </StyledTableHeadCell>
                          <StyledTableHeadCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            Submitted to Case Closed / Resolved
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableBodyCell align="center">
                            0.02 Hr
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4 Hr
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            3 Days
                          </StyledTableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                  backgroundColor: "rgb(255, 255, 255)",
                }}
                mt={3}
              >
                <Box
                  sx={{
                    borderRadius: "6px 6px 0px 0px",
                    backgroundColor: "#F5F8FF",
                    p: 3,
                  }}
                >
                  <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                    Overall Count By Service Type
                  </StyledPrimaryTypography>
                </Box>
                <Box sx={{ p: 3 }}>
                  <TableContainer
                    sx={{
                      mt: 3,
                      border: "2px solid rgb(229, 231, 235)",
                      borderRadius: "4px",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableHeadCell align="center">
                            Cargo Lift
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Light Bulb
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Air Con
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Security Clearance
                          </StyledTableHeadCell>
                          <StyledTableHeadCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            Miscellaneous Forms
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableBodyCell align="center">
                            1
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            3
                          </StyledTableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={3}
              >

                <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: '6px 6px 0px 0px' }}>
                  <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                    Median Days to Completion Reporting Time
                  </StyledPrimaryTypography>
                </Box>

                <StyledPrimaryTypography sx={{ textAlign: "center", p: 2, }}>
                  3 Days
                </StyledPrimaryTypography>

              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={3}
              >
                <Box
                  sx={{
                    backgroundColor: "#F5F8FF",
                    p: 3,
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                    Median Days to Close Service Requests
                  </StyledPrimaryTypography>
                </Box>
                <Box sx={{ p: 3 }}>
                  <TableContainer
                    sx={{
                      mt: 3,
                      border: "2px solid rgb(229, 231, 235)",
                      borderRadius: "4px",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableHeadCell align="center">
                            Cargo Lift
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Light Bulb
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Air Con
                          </StyledTableHeadCell>
                          <StyledTableHeadCell align="center">
                            Security Clearance
                          </StyledTableHeadCell>
                          <StyledTableHeadCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            Miscellaneous Forms
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ width: "100%" }}>
                          <StyledTableBodyCell align="center">
                            1 Day
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4 Days
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4 Days
                          </StyledTableBodyCell>
                          <StyledTableBodyCell align="center">
                            4 Days
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            style={{ borderRight: "none" }}
                            align="center"
                          >
                            3 Days
                          </StyledTableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Grid
                container
                spacing={3}
                sx={{ marginTop: "10px", marginBottom: "20px" }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                      backgroundColor: "rgb(255, 255, 255)",
                      marginTop: "20px",
                    }}

                  >
                    <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: "6px 6px 0px 0px" }}>
                      <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                        Median Time for the categories
                      </StyledPrimaryTypography>
                    </Box>
                    <Box p={2}>
                      <ServiceMediaGraph />
                    </Box>

                  </Box>
                </Grid>
                <Grid item xs={6}>

                  <Box
                    sx={{
                      border: "1.5px solid rgb(229, 231, 235)",
                      borderRadius: "6px",
                      backgroundColor: "rgb(255, 255, 255)",
                      marginTop: "20px",
                    }}

                  >
                    <Box sx={{ backgroundColor: "#F5F8FF", p: 2, borderRadius: "6px 6px 0px 0px" }}>
                      <StyledPrimaryTypography sx={{ textAlign: "center" }}>
                        Percent Wise Inc / Dec Based On Categories
                      </StyledPrimaryTypography>
                    </Box>
                    <Box p={2}>
                      <ServicePercentWiseGraph />
                    </Box>

                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "6px 6px 0px 0px",
                    backgroundColor: "#F5F8FF",
                    padding: "30px 16px 16px 16px",
                  }}
                >
                  <Box>
                    <StyledPrimaryTypography>
                      Aircon Service Request : Jan-2024 to Nov-2024
                    </StyledPrimaryTypography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#F5F8FF",
                    padding: "10px 16px 16px 16px",
                  }}
                >
                  <StyledPrimaryTypography
                    sx={{ textAlign: "center", marginTop: "25px" }}
                  >
                    Month-on-Month Comparison
                  </StyledPrimaryTypography>
                  <StyledPrimaryTypography sx={{ marginTop: "25px" }}>
                    Total Income : $1800
                  </StyledPrimaryTypography>
                </Box>
                <AirconGraph />
              </Box>
              <Box
                sx={{
                  border: "1.5px solid rgb(229, 231, 235)",
                  borderRadius: "6px",
                }}
                mt={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "6px 6px 0px 0px",
                    backgroundColor: "#F5F8FF",
                    padding: "30px 16px 16px 16px",
                  }}
                >
                  <Box>
                    <StyledPrimaryTypography>
                      Light Bulb Service Requests : Jan-2024 to Nov-2024
                    </StyledPrimaryTypography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      views={["year", "month"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            "& .MuiInputBase-input": {
                              color: "#0F2C6F", // Text color
                            },
                            "& .MuiInputLabel-root": {
                              color: "#0F2C6F", // Label color
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor: "#2764F4", // Border color if needed
                            // },
                            "& .MuiSvgIcon-root": {
                              color: "#0F2C6F", // Icon color
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#F5F8FF",
                    padding: "10px 16px 16px 16px",
                  }}
                >
                  <StyledPrimaryTypography
                    sx={{ textAlign: "center", marginTop: "25px" }}
                  >
                    Month-on-Month Comparison
                  </StyledPrimaryTypography>
                  <StyledPrimaryTypography sx={{ marginTop: "25px" }}>
                    Total Income : $1500
                  </StyledPrimaryTypography>
                </Box>
                <LightBulbGraph />
              </Box>
            </Box>
          )}
        </Box>
      </div >
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dbData: state.DashboardReducer.data,
    getRealtimeTurnStileData: state.DashboardReducer.getRealtimeTurnStile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDbData: (data) => dispatch(DashboardAction.getDbData(data)),
    getRealtimeTurnStile: () =>
      dispatch(DashboardAction.getRealtimeTurnStileAction()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BmsManagerDashboard);
