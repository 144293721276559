import React from "react";
import Chart from "react-apexcharts";

const OverviewSevice = () => {
  const options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false, // Disable toolbar
      },
    },
    stroke: {
      curve: "smooth",
      width: 2, // Make the line thin
    },
    colors: ["#f01e2c", "#ffc107"], // Colors for Fault Report and Total
    dataLabels: {
      enabled: true,
      background: {
        enabled: false, // Disable background box
      },
      offsetY: -5,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    legend: {
      position: "top",
    },
  };

  const series = [
    {
      name: "Service Request",
      data: [4, 4, 5, 9, 10, 6, 7, 10, 10, 10],
    },
    {
      name: "Total",
      data: [6, 6, 9, 18, 19, 8, 9, 13, 13, 14],
    },
  ];

  return (
    <div style={{ marginTop: "25px", padding: "16px" }}>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default OverviewSevice;
