import React from "react";
import Chart from "react-apexcharts";

const FaultLocationGraph = () => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "Others",
      data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43],
    },
    {
      name: "Corridor",
      data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27],
    },
    {
      name: "Toilet",
      data: [11, 17, 15, 15, 21, 14, 11, 17, 15, 15, 21, 14],
    },
    {
      name: "Service/General Lift",
      data: [21, 7, 25, 13, 22, 8, 21, 7, 25, 13, 22, 8],
    },
  ];

  return (
    <div style={{ marginTop: "10px" }}>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default FaultLocationGraph;
